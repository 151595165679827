import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { dataResetData, dataResetDataAll, dataResetDataHistoryAll, dataResetDataLive, dataResetDataLiveAll, dataUpdateCustomIntervalType, dataUpdateIntervalType } from '../services/dataSlice';

export function useCustomNavigate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customNavigate = (from, to, state = {}, params = {}) => {
    switch (to) {
      case '/DashboardPage/DevicePanel':
      case '/DashboardPage/ConcentratorPanel':
        switch (from) {
          default:
            dispatch(dataResetDataAll());
            dispatch(dataResetDataLiveAll());
            dispatch(dataResetDataHistoryAll());
            dispatch(dataUpdateIntervalType("none"));
            dispatch(dataUpdateCustomIntervalType("none"));
        }
        break;
      case '/DashboardPage/DashboardDeviceScope/':
        switch (from) {
          case '/DashboardPage/ChartPage/':
            break;
          default:
            dispatch(dataUpdateIntervalType("live"));          
        }

      default:
        if (process.env.REACT_APP_ENV  !== 'production') {
          console.log('Nessuna operazione speciale per questa navigazione');
        }
    }

    // Naviga alla nuova pagina
    navigate(to, { state: { ...state, from } });
  };

  return customNavigate;
}
