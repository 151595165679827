import { Box, Button, CircularProgress, Dialog, Stack } from '@mui/material';
import React, { useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { AlertProvider, useAlert } from './GenAlert';
import { DateTime } from 'luxon';

function DateTimePickerExport({ handleConfirmButton, handleCancelButton, isLoading }) {
    const [startDateTime, setStartDateTime] = useState({ dateTime: null });
    const [endDateTime, setEndDateTime] = useState({ dateTime: null });
    const [error, setError] = useState(false);
    const { showAlert, closeAlert } = useAlert();

    const _handleStartDateTimeChange = (value) => {
        setStartDateTime({ dateTime: value });
        setEndDateTime({ dateTime: null }); // Reset endDateTime when startDateTime changes
        setError(false);
        closeAlert();
    };

    const _handleEndDateTimeChange = (value) => {
        setEndDateTime({ dateTime: value });

        if (startDateTime.dateTime && value && startDateTime.dateTime < value) {
            setError(false);
            closeAlert();
        } else {
            setError(true);
            showAlert({
                title: '',
                message: 'Error in setting up time interval',
                severity: 'error',
            });
        }
    };

    const _handleApplyClick = () => {
        if (startDateTime.dateTime && endDateTime.dateTime && !error) {
            handleConfirmButton(true, startDateTime.dateTime, endDateTime.dateTime);
        } else {
            console.error("L'intervallo di date e orari non è valido.");
        }
    };

    const _handleCancelClick = () => {
        setStartDateTime({ dateTime: null });
        setEndDateTime({ dateTime: null });
        setError(false);
        closeAlert();
    };

    return (
        <div>
            <Stack direction="row" spacing={2}>
                <Box spacing={1} display="flex" alignItems="center" justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterLuxon} timeZone="Europe/Rome">
                        <DateTimePicker
                            label="Interval Start"
                            value={startDateTime.dateTime}
                            onChange={_handleStartDateTimeChange}
                            disableFuture
                            ampm={false}
                            disabled={isLoading || !!startDateTime.dateTime}
                            format='dd/MM/yyyy HH:00'
                            views={['year', 'month','day','hours']}
                            minutesStep={0}
                            slotProps={{ popper: { placement: 'left' }}}
                        />
                    </LocalizationProvider>
                </Box>
                <Box spacing={1} display="flex" alignItems="center" justifyContent="center">
                    <LocalizationProvider dateAdapter={AdapterLuxon} timeZone="Europe/Rome">
                        <DateTimePicker
                            label="Interval End"
                            value={endDateTime.dateTime}
                            onChange={_handleEndDateTimeChange}
                            disableFuture
                            ampm={false}
                            disabled={isLoading || !startDateTime.dateTime || !!endDateTime.dateTime}
                            format='dd/MM/yyyy HH:00'
                            views={['year', 'month','day','hours']}
                            minutesStep={0}
                            minDate={startDateTime.dateTime}
                            maxDate={startDateTime.dateTime ? startDateTime.dateTime.plus({ days: 30 }) : null}
                            slotProps={{ popper: { placement: 'right' }}}
                        />
                    </LocalizationProvider>
                </Box>
            </Stack>
            <Stack
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                p={1}
            >
                <Button
                    sx={{
                        width:"30%",
                        height:"100%"
                    }}
                    disabled={!startDateTime.dateTime || !endDateTime.dateTime || error}
                    onClick={_handleApplyClick}
                >
                    CONFIRM
                </Button>
                <Button
                    sx={{
                        width:"30%",
                        height:"100%"
                    }}
                    disabled={false}
                    onClick={_handleCancelClick}
                >
                    CANCEL
                </Button>
            </Stack>
        </div>    
    )
}

function GenExportCsv({ handleCreateCsvLiveClick, isLoading }) {

    const [onDialogOpen, setOnDialogOpen] = useState(false);

    const _handleCreateCsvLiveClick = (intervalEnFlag, startDateTime, endDateTime) => {
        handleCreateCsvLiveClick(intervalEnFlag, startDateTime, endDateTime);
        setOnDialogOpen(false);
    }

    const handleDialogOpen = () => {
        setOnDialogOpen(true);
    }

    function handleDialogClose(event) {
        setOnDialogOpen(false);
    }
    
    return (
        <Box
            p={2}
            spacing={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
        >
            <Button
                onClick={handleDialogOpen}
                disabled={isLoading}
            >
                {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                ) : (
                    'Export CSV'
                )}
            </Button>
            <Dialog
                onClose={handleDialogClose}
                open={onDialogOpen}
            >
                <Box p={2}>
                    <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                        <Box>
                            Export Csv
                        </Box>
                        <Box alignItems="center" justifyContent="center">
                            <AlertProvider>
                                <DateTimePickerExport 
                                    handleConfirmButton={_handleCreateCsvLiveClick}
                                    handleCancelButton={handleDialogClose}
                                    isLoading={isLoading}
                                />
                            </AlertProvider>
                        </Box>
                    </Stack>
                </Box>
            </Dialog>
        </Box>
    );
}

export default GenExportCsv;
