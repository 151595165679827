import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import AlertDialog from './GenDialog';


function ResetLiveButton({handleResetLiveClick}) {

    const [onDialogOpen, setOnDialogOpen] = useState([false]);

    const _handleResetLiveClick = () => {
        handleResetLiveClick()
        setOnDialogOpen([false])
    }

    const handleDialogOpen = () => {
        setOnDialogOpen([true])
    }

    function handleDialogClose(event) {
        setOnDialogOpen([false])
    }
    
    return (
        <Box
            p={2}
            spacing={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
        >
            <Button 
                variant="contained"
                color="primary"
                onClick={handleDialogOpen}
            >
                Reset Live
            </Button>
            <AlertDialog
                dialogTitle={"Do you want to reset live section?"} 
                dialogContextText={""}
                buttonLabel0={"Confirm"}
                buttonLabel1={"Cancel"}
                open={onDialogOpen[0]} 
                handleClose={handleDialogClose}
                handleButton0={_handleResetLiveClick}
            />
        </Box>
  );
}

export default ResetLiveButton;