import { createSlice } from '@reduxjs/toolkit';
import { dataUpdateData } from './dataSlice';

const temp_devices_count = 0

const temp_devices_vect = []

const initialState = {
    devices: temp_devices_vect,
    count: temp_devices_count,
};

export const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        devicesAddDevice: {
            reducer: (state, action) => {

                const oldData = state.devices;
                
                const newData = [...oldData, action.payload];
                state.devices = newData;
                state.count += 1;
            },
            prepare: (json) => {

                //See if some parsing makes sense

                return { payload: json }
            }
        },
        devicesAddAllDevices: {
            reducer: (state, action) => {
                const newData = [...action.payload];
                state.devices = newData;

                state.count += action.payload.length
            },
            prepare: (json) => {

                //See if some parsing makes sense

                return { payload: json }
            }
        },
        devicesDeleteDevice: {
            reducer: (state, action) => {

                const oldData = state.devices

                const index = oldData.findIndex(element => element.id === action.payload)
                
                let newArray = [...state.devices];
                newArray.splice(index, 1)
                state.devices = newArray
                
                state.count -= 1
            },
            prepare: (delDeviceId) => {

                //See if some parsing makes sense

                return { payload: delDeviceId }
            }
        },
        devicesModifyDevice: {
            reducer: (state, action) => {

                const oldData = state.devices

                const index = oldData.findIndex(element => element.id === action.payload.id)
                
                let newArray = [...state.devices];
                newArray[index] = action.payload;
                state.devices = newArray
            },
            prepare: (modDeviceId) => {

                //See if some parsing makes sense

                return { payload: modDeviceId }
            }
        },
        devicesModifyStatus: (state, action) => {

            const status = action.payload.status;

            const index = state.devices.findIndex((element) => element.id === action.payload.id);

            if(index >= 0)
            {
                state.devices[index].status = status;
            }  
        }
    },
    extraReducers: (builder) => { 
        builder
            .addCase(dataUpdateData, (state, action) => {
                const oldData = state.devices
                const deviceId = action.payload.deviceData.id;
                const lastSeen = action?.payload?.deviceMeta?.lastSeen || 0;

                const index = oldData.findIndex(element => element.id === deviceId);
                
                if(index >= 0)
                {
                    let newArray = [...state.devices];
                    newArray[index].status = "online";
                    newArray[index].lastSeen = new Date(lastSeen);
                    state.devices = newArray;
                }
            })
        }
});
 
export const {  
                devicesAddDevice,
                devicesAddAllDevices,
                devicesDeleteDevice,
                devicesModifyDevice,
                updateAllCallback,
                devicesModifyStatus
            } = devicesSlice.actions;

export const selectDevices = (state) => state.devices.devices;
export const selectDevicesCount = (state) => state.devices.count;

export default devicesSlice.reducer;
