import { useDispatch } from 'react-redux';
import { startTimer, stopTimer, clearTimerId, setTimerId } from '../services/timerSlice';
;

const useTokenExpireTimer = () => {
  const dispatch = useDispatch();

  const startTokenExpireTimer = (callback, expireTime) => {

    const newTimerId = setTimeout(() => {
      callback();
    }, expireTime);

    dispatch(setTimerId({ id: 'tokenExpireTimer', timerId: newTimerId }));

    dispatch(startTimer({ id: 'tokenExpireTimer', callback: () => {} }));
  };

  const stopTokenExpireTimer = () => {
    dispatch(stopTimer({ id: 'tokenExpireTimer' }));
  };

  const resetTokenExpireTimer = () => {
    dispatch(clearTimerId({ id: 'tokenExpireTimer' }));
  };

  const refreshTokenExpireTimer = (expireTime) => {
    stopTokenExpireTimer();
    startTokenExpireTimer(timerCallback, expireTime);
  };

  const timerCallback = () => {
    alert("L'accessToken è scaduto. Effettua nuovamente l'accesso.");
    // Puoi eseguire altre azioni qui, come il logout dell'utente
  };

  return {
    startTokenExpireTimer,
    stopTokenExpireTimer,
    resetTokenExpireTimer,
    refreshTokenExpireTimer,
  };
};

export default useTokenExpireTimer;
