import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography, Box, IconButton } from "@mui/material";
import logoImageWebP from '../assets/astreoR_simbolo_tramonto_positivo_MEDIUM.webp';
import logoImagePNG from '../assets/astreoR_simbolo_tramonto_positivo_MEDIUM.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import backgroundImageWebP from '../assets/blunotte_simbolo.webp';
import backgroundImageJPG from '../assets/blunotte_simbolo.jpg';
import { useCustomNavigate } from "../navigation/customNavigate";

const link_astreo_linkedin = process.env.REACT_APP_LINK_ASTREO_LINKEDIN || "https://www.linkedin.com/company/astreotech/"
const link_astreo_website = process.env.REACT_APP_LINK_ASTREO_WEBSITE || "https://www.astreotech.com/"

const AuthenticationPage = () => {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
    const customNavigate = useCustomNavigate()
    return (
        <Box 
            sx={{
                backgroundImage: `url(${backgroundImageWebP}), url(${backgroundImageJPG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    borderRadius: 3,
                    width: 500,
                    bgcolor: 'white',
                    border: '1px solid #F6F8FC',
                    p: 4,
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.4)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <picture>
                    <source srcSet={logoImageWebP} type="image/webp" />
                    <source srcSet={logoImagePNG} type="image/png" />
                    <img src={logoImagePNG} alt="Logo" width={110} height={100} loading="lazy" />
                </picture>
                <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                    ASTREO WEB APP
                </Typography>
                {isAuthenticated ? (
                    <>
                        <Button
                            sx={{
                                mt: 2,
                                borderRadius: 3,
                                border: "1px solid black",
                                width: "48%",
                                height: "60%",
                                bgcolor: "#031AE8",
                                '&:hover': { backgroundColor: "#FF4F5A" }
                            }}
                            onClick={() => customNavigate( "", '/Loading', null, {} )}
                        >
                            <Typography color="white">GO TO DASHBOARD</Typography>
                        </Button>
                        <Button
                            sx={{
                                mt: 2,
                                borderRadius: 3,
                                border: "1px solid black",
                                width: "48%",
                                height: "60%",
                                bgcolor: "#031AE8",
                                '&:hover': { backgroundColor: "#FF4F5A" }
                            }}
                            onClick={() => {
                                logout({ returnTo: process.env.REACT_APP_AUTH0_LOGOUT_CALLBACK_URL || `http://127.0.0.1:3000` })
                            }}
                        >
                            <Typography color="white">LOGOUT</Typography>
                        </Button>
                    </>
                ) : (
                    <Button
                        sx={{
                            mt: 2,
                            borderRadius: 3,
                            border: "1px solid black",
                            width: "48%",
                            height: "60%",
                            bgcolor: "#031AE8",
                            '&:hover': { backgroundColor: "#FF4F5A" }
                        }}
                        onClick={() => loginWithRedirect()}
                    >
                        <Typography color="white">LOGIN</Typography>
                    </Button>
                )}
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2, width: '100%' }}>
                    <IconButton
                        onClick={() => window.open(link_astreo_linkedin, '_blank')}
                        color="primary"
                        aria-label="LinkedIn"
                    >
                        <LinkedInIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => window.open(link_astreo_website, '_blank')}
                        aria-label="Website"
                    >
                        <picture>
                            <source srcSet={logoImageWebP} type="image/webp" />
                            <source srcSet={logoImagePNG} type="image/png" />
                            <img src={logoImagePNG} alt="Your Website" style={{ width: 22, height: 20 }} loading="lazy" />
                        </picture>
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
}

export default AuthenticationPage;
