import React, { useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { Box, Button, TextField, Typography, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { devicesModifyDevice } from '../../../../services/devicesSlice';
import AlertDialog from '../../../GeneralComponents/GenDialog';
import { putToDatabase } from '../../../../db/dbAccessFunctions';
import { selectUserInfo, selectUserToken } from '../../../../services/userSlice';

const ENDPOINT_BASE = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';
const ENDPOINT_BASE_TEST = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';

const DashboardGeneralConfiguration = ({deviceData}) => {

    const [updatedData, setUpdatedData] = useState(deviceData);
    const [onDialogOpen, setOnDialogOpen] = useState([false]);
    const [isModified, setIsModified] = useState(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);

    const accToken = useSelector(selectUserToken)
    const userInfo = useSelector(selectUserInfo)

    let url = null;
    if (process.env.REACT_APP_ENV  === 'production') {
        url = `https://${ENDPOINT_BASE}/devices/`;
    } else if (process.env.REACT_APP_ENV  === 'test') {
        url = `https://${ENDPOINT_BASE_TEST}/devices/`;
    }
    else{
        url = `http://${ENDPOINT_BASE}/devices/`;
    }

    const dispatch = useDispatch()

    const handleNameChange = (event) => {
        setUpdatedData({
            ...updatedData,
            name: event.target.value,
        });
        setIsModified(true);
    };
    
    const handleDescriptionChange = (event) => {
        setUpdatedData({
            ...updatedData,
            description: event.target.value,
        });
        setIsModified(true);
    };
    
    const handleLocationLatChange = (event) => {
        setUpdatedData({
            ...updatedData,
            location: {
                ...updatedData.location,
                latitude: event.target.value,
            },
        });
        setIsModified(true);
    };
    
    const handleLocationLongChange = (event) => {
        setUpdatedData({
            ...updatedData,
            location: {
                ...updatedData.location,
                longitude: event.target.value,
            },
        });
        setIsModified(true);
    };
    
    const handleLocationAltChange = (event) => {
        setUpdatedData({
            ...updatedData,
            location: {
                ...updatedData.location,
                altitude: event.target.value,
            },
        });
        setIsModified(true);
    };
    
    const handleOnlineTOChange = (event) => {
        setUpdatedData({
            ...updatedData,
            onlineTimeout: event.target.value,
        });
        setIsModified(true);
    };
    
    const handleOptionCostChange = (event) => {
        setUpdatedData({
            ...updatedData,
            options: {
                ...updatedData.options,
                cost: event.target.value,
            },
        });
        setIsModified(true);
    };    

    const handleChangeModifyEnable = (event) => {
        
        if(isModified)
        {
            setOnDialogOpen([true])
        }
    };

    const handleSave = async () => {

        setIsSaveButtonLoading(true)

        const match = deviceData?.id.match(/^cl-[a-z]+-([0-9a-f]{16})$/);

        if (!match) {
            alert("ERROR IN SAVING")
            setIsSaveButtonLoading(false);
            return;
        }

        const hexPart = match[1];

        const queryParams = new URLSearchParams({
            client_id: userInfo.meta.company_id,
            sensor_id: hexPart,
        });

        const responseData = await putToDatabase(`${url}${userInfo.meta.company_id}/${hexPart}`, updatedData, accToken)

        if(responseData.ok) {
            dispatch(devicesModifyDevice(updatedData))
            alert("CORRECTLY SAVED")
        }
        else
        {
            alert("ERROR IN SAVING")
        }

        setIsSaveButtonLoading(false)
    };

    const handleDialogClose = (event) => {
        setOnDialogOpen([false])
    };

    const handleSaveButton = () => {

        handleSave()
        handleDialogClose()
        setIsModified(false)
    }

//    const handleTagsChange = (event) => {
//    };

//    const handleImageChange = (event) => {
//    };

    return(
        <Box>
            <Stack
                display="flex"
                spacing = {3}
            >
                <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Device Name
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                            align:'justify'
                        }}
                    >
                        <TextField
                            defaultValue={deviceData?.name}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                width: "30%"
                            }}
                            onChange = {handleNameChange}
                        />
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h5" 
                        sx={{
                            align:'justify'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Description
                        </Typography>
                        <TextField
                            defaultValue={deviceData?.description}
                            multiline
                            rows={4}
                            inputProps={{ maxLength:500 }}
                            sx={{
                                width: "60%",
                            }}
                            onChange = {handleDescriptionChange}
                        />
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h5" 
                        sx={{
                            align:'justify'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Location
                        </Typography>
                        <Stack
                            spacing={1}
                        >
                            <Typography>
                                Latitude
                            </Typography>
                            <TextField
                                defaultValue={deviceData?.location?.latitude}
                                sx={{
                                    width: "60%",
                                }}
                                onChange = {handleLocationLatChange}
                                inputProps={{
                                    type: "number",
                                    step: "0.0001",
                                    min: -90,
                                    max: 90
                                }}
                            />
                            <Typography>
                                Longitude
                            </Typography>
                            <TextField
                                defaultValue={deviceData?.location?.longitude}
                                sx={{
                                    width: "60%",
                                }}
                                onChange = {handleLocationLongChange}
                                inputProps={{
                                    type: "number",
                                    step: "0.0001",
                                    min: -180,
                                    max: 180
                                }}
                            />
                            <Typography>
                                Altitude
                            </Typography>
                            <TextField
                                defaultValue={deviceData?.location?.altitude}
                                sx={{
                                    width: "60%",
                                }}
                                onChange = {handleLocationAltChange}
                                inputProps={{
                                    type: "number",
                                    step: "0.01",
                                    min: -10000,
                                    max: 10000
                                }}
                            />
                        </Stack>
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h5" 
                        sx={{
                            align:'justify'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Tags
                        </Typography>
                        <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            options={[]}
                            getOptionLabel={(option) => option.title}
                            defaultValue={[]}
                            renderInput={(params) => (
                                <TextField {...params} label="" />
                            )}
                            sx={{ width: '500px' }}
                        />
                    </Typography>                    
                </Box>
                <Box>
                    <Typography
                        variant="h5" 
                        sx={{
                            align:'justify'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Cost (€/kWh)
                        </Typography>
                        <TextField
                            defaultValue={deviceData?.options?.cost}
                            sx={{
                                width: "60%",
                            }}
                            onChange = {handleOptionCostChange}
                            inputProps={{
                                maxLength:50,
                                step:"0.001",
                                type:"number",
                                min: "0"
                            }}
                        />
                    </Typography>                    
                </Box>
                {/*<Box
                    width="100%"
                >
                    <Typography
                        variant="h5" 
                        sx={{
                            align:'justify'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Image
                        </Typography>
                        <Stack
                            spacing={2}
                            pt={5}
                            width="100%"
                            alignItems="center"
                        >
                            <Avatar
                                src={""}
                                sx={{ width: 250, height: 250 }}
                            />
                            <input type="file" accept='.png, .jpg, .jpeg' onChange={()=>{}} />
                        </Stack>
                    </Typography>                    
                </Box>*/}
                <Box>
                    <Typography
                        variant="h5" 
                        sx={{
                            align:'justify'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            Online Timeout (seconds)
                        </Typography>
                        <TextField
                            type="number"
                            defaultValue={deviceData?.onlineTimeout}
                            sx={{
                                width: "60%",
                            }}
                            onChange = {handleOnlineTOChange}
                            inputProps={{
                                maxLength:50,
                                step:"0.001",
                                type:"number",
                                min:"0"
                            }}
                        />
                    </Typography>
                </Box>
                {/*<Box>
                    <Stack>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Notify me if the device goes offline" />
                        </FormGroup>
                    </Stack>
                </Box>*/}
                <Box
                    display='flex'
                    justifyContent='flex-end'
                >
                    <Button 
                        variant={ isModified ? 'contained' : 'outlined' }
                        onClick={handleChangeModifyEnable}
                        disabled={isSaveButtonLoading}
                    >
                        {isSaveButtonLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'SAVE'
                        )}
                    </Button>
                    <AlertDialog
                        dialogTitle={"Do you want to save changes?"} 
                        dialogContextText={""}
                        buttonLabel0={"Save"}
                        buttonLabel1={"Cancel"}
                        open={onDialogOpen[0]} 
                        handleClose={handleDialogClose}
                        handleButton0={handleSaveButton}
                    />
                </Box>
            </Stack>
        </Box>
    );
}

export default DashboardGeneralConfiguration;