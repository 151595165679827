import { Box, Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { devicesDeleteDevice, devicesModifyDevice } from "../../services/devicesSlice";

function GenButtonList ({anchorElement, onBClose, id}) {

  const dispatch = useDispatch()

  function _onClose() {
    onBClose(null)
  }

  function onHandleModify() {
    dispatch(devicesModifyDevice(id))
    _onClose()
  }

  function onHandleDelete() {
    dispatch(devicesDeleteDevice(id))
    _onClose()
  }

  return (
    <Box
      overflow="auto"
    >
      <Menu
        id="column-menu"
        anchorEl={anchorElement}
        aria-labelledby="demo-positioned-button"
        keepMounted
        open={Boolean(anchorElement)}
        onClose={_onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
            <MenuItem sx={{ width: 120 }} onClick={onHandleModify}>
                Modify
            </MenuItem>
            <MenuItem sx={{ width: 120 }} onClick={onHandleDelete}>
                Delete
            </MenuItem>
      </Menu>
    </Box>
  )
};

export default GenButtonList;