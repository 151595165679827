import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js/auto';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

Chart.register(...registerables);

const DashboardDynamicChart = ({ data, type, avgEnabled, gain, channel, sign, range, chartType, labTitle, labels, color, fill, loading, fullScreen, timeUnit, intervalMinutes, lastElBlEnabled, lastElem }) => {
  const chartRef = useRef(null);
  const chart = useRef(null);
  const channelData = useRef([]);
  const overlayRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragEnd, setDragEnd] = useState({ x: 0, y: 0 });
  const [zoomActive, setZoomActive] = useState(false);

  const drawSelectionRect = () => {
    if (!isDragging || !overlayRef.current) return;

    const ctx = overlayRef.current.getContext('2d');
    if (!chart.current) return;
    const chartArea = chart.current.chartArea;

    const xStart = Math.max(dragStart.x, chartArea.left);
    const xEnd = Math.min(Math.max(dragEnd.x, chartArea.left), chartArea.right);

    const height = chartArea.bottom - chartArea.top;

    ctx.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
    ctx.fillStyle = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.1)`;
    ctx.fillRect(xStart, chartArea.top, xEnd - xStart, height);
  };

  const onMouseDown = (event) => {
    if (!event) return;

    const { offsetX, offsetY } = event;
    const { top, bottom, left, right } = chart.current.chartArea;

    if (offsetX >= left && offsetX <= right && offsetY >= top && offsetY <= bottom) {
      setIsDragging(true);
      setDragStart({ x: offsetX, y: offsetY });
    }
  };

  const onMouseMove = (event) => {
    if (!isDragging || !event) return;

    const { offsetX, offsetY } = event;
    if (offsetX !== undefined && offsetY !== undefined) {
      const endY = Math.max(Math.min(offsetY, chart.current.chartArea.bottom), chart.current.chartArea.top);
      setDragEnd({ x: offsetX, y: endY });

      drawSelectionRect();
    }
  };

  const onMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);

      if (chart.current) {
        const minX = Math.max(Math.min(dragStart.x, dragEnd.x), chart.current.chartArea.left);
        const maxX = Math.min(Math.max(dragStart.x, dragEnd.x), chart.current.chartArea.right);
        const minVal = chart.current.scales.x.getValueForPixel(minX);
        const maxVal = chart.current.scales.x.getValueForPixel(maxX);

        chart.current.options.scales.x.min = minVal;
        chart.current.options.scales.x.max = maxVal;
        chart.current.update();
        setZoomActive(true);
      }

      const ctx = overlayRef.current.getContext('2d');
      ctx.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
    }
  };

  const handleResetZoom = () => {
    if (chart.current) {
      chart.current.options.scales.x.min = undefined;
      chart.current.options.scales.x.max = undefined;
      chart.current.update();
      setZoomActive(false);
    }
  };

  useEffect(() => {
    const canvas = chartRef.current;

    if (!canvas) {
      return;
    }

    canvas.addEventListener('mousedown', onMouseDown);
    canvas.addEventListener('mousemove', onMouseMove);
    canvas.addEventListener('mouseup', onMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', onMouseDown);
      canvas.removeEventListener('mousemove', onMouseMove);
      canvas.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, dragStart, dragEnd]);

  useEffect(() => {
    if (!loading) {
      if (data && labels) {
        const filteredData = data?.filter((item) => item !== undefined && item[channel] !== undefined);
        channelData.current = filteredData?.map((item) => item[channel] * gain);

        const dataset_prsd = labels.map((timestamp, index) => {
          return {
            x: timestamp.toMillis(),
            y: channelData.current[index],
          };
        });

        const minY = 0;
        const max = Math.max(...channelData.current);
        const min = Math.min(...channelData.current);

        const media = (max - min) / 2;

        const maxPercentage = Math.floor((2 * (min + media)) + (2 * (min + media) * (20 / 100)));
        const maxY = Math.max(maxPercentage, max > maxPercentage ? max + max * 20 / 100 : 0);

        if (!chart.current) {
          chart.current = new Chart(chartRef.current, {
            type: chartType,
            data: {
              labels: labels,
              datasets: [
                {
                  label: labTitle,
                  data: dataset_prsd,
                  fill: fill,
                  borderColor: color,
                  pointBackgroundColor: color,
                  backgroundColor: (data, index) => {
                    if (index === channelData.current.length - 1 && lastElBlEnabled) {
                      return color + '20';
                    } else {
                      return chartType === 'line' ? `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.3)` : color;
                    }
                  },
                  tension: 0.1,
                  pointRadius: 2,
                  pointHoverRadius: 5,
                  hoverBackgroundColor: color + '80',
                },
              ],
            },
            options: {
              animation: false,
              parsing: false,
              spanGaps: true,
              responsive: true,
              maintainAspectRatio: fullScreen,
              interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false,
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    tooltipFormat: 'dd/MM/yyyy HH:mm:ss.S',
                    displayFormats: {
                      millisecond: 'HH:mm:ss.S',
                      second: 'HH:mm:ss',
                      minute: 'HH:mm:ss',
                      hour: 'HH:mm:ss',
                      day: 'dd MMM',
                      week: 'dd/MM/yyyy',
                      month: 'MMM yyyy',
                      quarter: 'QQQ - yyyy',
                      year: 'yyyy',
                    },
                  },
                  ticks: {
                    source: 'auto',
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 6,
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: sign,
                  },
                  min: minY,
                  max: maxY,
                  normalization: true,
                },
              },
              plugins: {
                decimation: {
                  enabled: true,
                  algorithm: 'lttb',
                  samples: 100,
                  threshold: 200,
                },
                tooltip: {
                  callbacks: {
                    title: (context) => {
                      const timestamp = context[0].parsed.x;

                      let returnObj = '';
                      let offset = 0;

                      if (intervalMinutes > 1) {
                        let timeUnit = '';
                        let startTimeInterval = '';
                        let endTimeInterval = '';

                        if (intervalMinutes === 60) {
                          timeUnit = 'hour';
                          startTimeInterval = DateTime.fromMillis(timestamp);
                          endTimeInterval = DateTime.fromMillis(timestamp).endOf(timeUnit);

                          returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                        } else if (intervalMinutes === 1440) {
                          timeUnit = 'day';
                          startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);

                          returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}`;
                        } else {
                          timeUnit = 'minute';
                          offset = intervalMinutes;
                          startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);
                          endTimeInterval = DateTime.fromMillis(timestamp).plus({ minutes: offset });

                          returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                        }
                      } else {
                        const timestampParsed = DateTime.fromMillis(timestamp);
                        returnObj = `${timestampParsed.toFormat('ccc, d MMM yyyy')}, ${timestampParsed.toFormat('HH:mm:ss')}`;
                      }

                      return returnObj;
                    },
                    label: function (context) {
                      const label = context.dataset.label || '';
                      let value = context.parsed.y;
                      value = value.toFixed(2);
                      return `${label}: ${value} ${sign}`;
                    },
                  },
                },
              },
            },
          });

          chartRef.current.addEventListener('mousedown', onMouseDown);
          chartRef.current.addEventListener('mousemove', onMouseMove);
          chartRef.current.addEventListener('mouseup', onMouseUp);
        } else {
          chart.current.data.labels = labels;
          chart.current.options.maintainAspectRatio = fullScreen;
          chart.current.options.scales.x.time.unit = timeUnit;
          chart.current.options.scales.y.title.text = sign;
          chart.current.data.datasets.forEach((dataset) => {
            dataset.fill = fill;
            dataset.data = dataset_prsd;
            dataset.borderColor = color;
            dataset.pointBackgroundColor = color;
            dataset.backgroundColor = (data, index) => {
              if (index === channelData.current.length - 1 && lastElBlEnabled) {
                return color + '20';
              } else {
                return chartType === 'line' ? `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, 0.3)` : color;
              }
            };
            dataset.hoverBackgroundColor = color + '80';
          });
          chart.current.options.scales.y.min = minY;
          chart.current.options.scales.y.max = maxY;

          // Aggiorna le callback dei tooltip in base alle nuove impostazioni
          chart.current.options.plugins.tooltip.callbacks = {
            title: (context) => {
              const timestamp = context[0].parsed.x;

              let returnObj = '';
              let offset = 0;

              if (intervalMinutes > 1) {
                let timeUnit = '';
                let startTimeInterval = '';
                let endTimeInterval = '';

                if (intervalMinutes === 60) {
                  timeUnit = 'hour';
                  startTimeInterval = DateTime.fromMillis(timestamp);
                  endTimeInterval = DateTime.fromMillis(timestamp).endOf(timeUnit);

                  returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                } else if (intervalMinutes === 1440) {
                  timeUnit = 'day';
                  startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);

                  returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}`;
                } else {
                  timeUnit = 'minute';
                  offset = intervalMinutes;
                  startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);
                  endTimeInterval = DateTime.fromMillis(timestamp).plus({ minutes: offset });

                  returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                }
              } else {
                const timestampParsed = DateTime.fromMillis(timestamp);
                returnObj = `${timestampParsed.toFormat('ccc, d MMM yyyy')}, ${timestampParsed.toFormat('HH:mm:ss')}`;
              }

              return returnObj;
            },
            label: function (context) {
              const label = context.dataset.label || '';
              let value = context.parsed.y;
              value = value.toFixed(2);
              return `${label}: ${value} ${sign}`;
            },
          };

          chart.current.update();
        }
      }
    }
  }, [data, sign, range, labels, loading, channel, fullScreen]);

  useEffect(() => {
    return () => {
      if (chart.current) {
        setZoomActive(false);
        chart.current.destroy();
        chart.current = null;
      }
    };
  }, [type]);

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', overflow: 'hidden' }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <canvas ref={chartRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }} />
          <canvas ref={overlayRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, pointerEvents: 'none' }} width={chartRef.current?.width} height={chartRef.current?.height} />
          {zoomActive && (
            <Tooltip title="Reset Zoom">
              <span>
                <IconButton
                  onClick={handleResetZoom}
                  sx={{
                    position: 'absolute',
                    top: '2rem',
                    right: '1rem',
                    zIndex: 3,
                    '& .MuiSvgIcon-root': {
                      fontSize: '1rem',
                    },
                  }}
                >
                  <ZoomOutMapIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      )}
    </Box>
  );
};

export default DashboardDynamicChart;
