import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';

Chart.register(...registerables);

const decimation = {
  enabled: true,
  algorithm: 'lttb',
  samples: 50
};

const DashboardChartDummy = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const now = DateTime.now().toMillis();
    const oneHour = 60 * 60 * 1000; // Millisecondi in un'ora
    
    // Generazione delle etichette (timestamp ogni ora)
    const labels = Array.from({ length: 600 }, (_, i) => now - (600 - i) * oneHour);

    // Generazione dei dati per l'onda triangolare
    const data = labels.map((timestamp, index) => {
      return {
        x: timestamp,
        y: Math.sin(index / 10) // Esempio di valore y
      };
    });

    console.log("Numero di punti nel dataset originale:", data.length);

    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [{
          label: 'Onda Triangolare',
          data: data,
          borderColor: 'rgb(255, 99, 132)',
          tension: 0.1
        }]
      },
      options: {
        // Turn off animations and data parsing for performance
        animation: false,
        parsing: false,
    
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        plugins: {
          decimation: decimation,
        },
        scales: {
          x: {
            type: 'time',
            ticks: {
              source: 'auto',
              // Disabled rotation for performance
              maxRotation: 0,
              autoSkip: true,
            }
          }
        }
      }
    });

    return () => {
      myChart.destroy();
    };
  }, []);

  return (
    <canvas ref={chartRef} width="400" height="400"></canvas>
  );
};

export default DashboardChartDummy;
