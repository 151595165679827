import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, InputAdornment, Stack, TextField } from '@mui/material';
import GenGauge from '../../../GeneralComponents/GenGauge';
import Co2Icon from '@mui/icons-material/Co2';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import { dateFormat, dateFormatToString, isParsableDate } from '../../../../utils/utils';

const DashboardSensorCard = ({ sensorData, timeInterval, avgEnabled, channel, tepFactor, tepType, renderSize, timestamp, text, loading }) => {
    
    const [size, setSize] = useState(renderSize);
    const [updated, setUpdated] = useState(false);
    const [normalizationFactor, setNormalizationFactor] = useState(0.01);

    let displayedData = 0;
    let displayedSign = '';
    let normalizationSign = "";

    switch(tepType)
    {
        case 0:
            displayedSign = 'tep/t';
            normalizationSign = 't';
        break;
        default:
            displayedSign = '';
            normalizationSign = '';
    }

    if (sensorData && sensorData.length > 0) {
        
        let accumulator = 0;
        if (0) {       
            if (sensorData && sensorData.length > 0) {
                sensorData.forEach((element) => {
                    if (
                        element &&
                        element.values &&
                        element.values[0] &&
                        element.values[0].convertedVal &&
                        element.values[0].convertedVal[channel] &&
                        (!avgEnabled || (element.body?.avg !== null && element.body?.avg !== undefined))
                    ) {
                        accumulator += element.values[0].convertedVal[channel];
                    }
                });
            }
        } else {
            if (sensorData && sensorData.length > 0) {
                sensorData.forEach((element) => {
                    if (
                        element &&
                        element.values &&
                        element.values.data &&
                        (!avgEnabled || (element.body?.avg !== null && element.body?.avg !== undefined))
                    ) {
                        for (let count = 0; count < element.values.data.length; count++) {
                            accumulator += element.values.data[count].convertedVal[channel] / (element.values.data.length);
                        }
                    }
                });
            }  
        }
        let timeIntervalfactor;

        switch (timeInterval) {
            case 'live':
                timeIntervalfactor = 1 / 60;
                break;
            case 'hour':
                timeIntervalfactor = 1 / 60;
                break;
            case 'day':
                timeIntervalfactor = 1 / 12;
                break;
            case 'week':
                timeIntervalfactor = 1;
                break;
            case 'month':
                timeIntervalfactor = 24;
                break;
            case 'all':
                timeIntervalfactor = 24;
                break;
            default:
                timeIntervalfactor = 60;
        }

        displayedData = accumulator * timeIntervalfactor * tepFactor;

        switch(tepType)
        {
            case 0:
                displayedData = displayedData / normalizationFactor;
            break;
            default:
                displayedData = 0;
        }
    }

    useEffect(() => {
        setSize(renderSize);
        setUpdated(true);
    
        const timer = setTimeout(() => {
          setUpdated(false);
        }, 1500);
    
        return () => clearTimeout(timer);
    }, [renderSize, sensorData]);

    useEffect(() => {
        setSize(renderSize);
    }, [renderSize]);

    const handleNormalizationChange = (event) => {
        const value = parseFloat(event.target.value);
        setNormalizationFactor(value > 0 ? value : 1.0);
    };

    const convertedTimestamp = isParsableDate(timestamp)
        ? dateFormatToString(dateFormat(new Date(timestamp)), 'dd/MM/yyyy HH:mm:ss')
        : 'Waiting';

    return (
        <Box width="100%" height="100%">
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box width="100%" height="100%">
                    {size === "small" && (
                        <Stack
                            display="flex"
                            alignContent="center"
                            justifyContent="space-evenly"
                            direction="row"
                            width="100%"
                            height="100%"
                        >
                            <Box sx={{ width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', color: updated ? 'green' : 'initial' }} >
                                <InvertColorsIcon fontSize="large" />
                            </Box>
                            <Box sx={{ width: "45%", display: 'flex', justifyContent: "flex-start", alignItems: 'center' }}>
                                <Stack direction="column" justifyContent="center" alignContent="center">
                                    <Typography
                                        sx={{ fontSize: '0.90rem', fontWeight: 'bold', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {text}
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: '0.65rem', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {convertedTimestamp}
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center' }}>
                            <TextField
                                type="number"
                                value={normalizationFactor}
                                onChange={handleNormalizationChange}
                                inputProps={{ min: 0.1, step: 0.1, title: '' }}
                                variant="standard"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment 
                                            position="start" 
                                        >
                                            <Typography sx={{ color: 'black' }}>
                                                {normalizationSign}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiInput-underline:before': {
                                        borderBottom: 'none',
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottom: 'none',
                                    },
                                }}
                            />
                            </Box>
                            <Box sx={{ width: "35%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row-reverse' }}>
                                <Typography
                                    sx={{ pr: 2, fontSize: '1.00rem', fontWeight: 'bold', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'right' }}
                                >
                                    {displayedData != null
                                    ? typeof displayedData === 'number'
                                        ? (
                                            <>
                                                {displayedData.toFixed(2)} 
                                                <Typography component="span" sx={{ ml: 0.25, fontSize: '0.9rem', fontWeight: 'bold' }}>
                                                    {displayedSign}
                                                </Typography>
                                            </>
                                            )
                                        : displayedData
                                    : 'waiting'}
                                </Typography>
                            </Box>
                        </Stack>     
                    )}
                    {size === "medium" && (
                        <Stack>
                            <Typography sx={{ fontSize: '1.5em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                                kW Orari
                            </Typography>
                            <Typography sx={{ fontSize: '2.0em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                                {sensorData?.type}
                            </Typography>
                            <Typography
                                sx={{ fontSize: '3.5em', color: updated ? 'green' : 'text.primary' }}
                                gutterBottom
                            >
                                {displayedData != null
                                    ? typeof displayedData === 'number'
                                    ? `${displayedData.toFixed(2)} ${displayedSign}`
                                    : displayedData
                                    : 'Waiting'}
                            </Typography>
                        </Stack>        
                    )}
                    {size === "large" && (
                        <Stack
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            alignContent="center"
                            direction="column"                 
                        >
                            <Box>
                                <Typography sx={{ fontSize: '1.5em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                                    kW Orari
                                </Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: '2.0em', color: updated ? 'green' : 'text.primary' }} gutterBottom>
                                    {sensorData?.type}
                                </Typography>
                            </Box>
                            <Box>
                                <GenGauge 
                                    value={sensorData === undefined ? 0.00 : (sensorData?.val)}
                                    data={[
                                        { name: 'Start', value: (sensorData === undefined ? 33.33 : (sensorData?.range[1] / 3)), color: '#00ff00' },
                                        { name: 'Middle', value: (sensorData === undefined ? 66.66 : (sensorData?.range[1] / 3)), color: '#0000ff' },
                                        { name: 'End', value: (sensorData === undefined ? 100.00 : (sensorData?.range[1] / 3)), color: '#ff0000' },
                                    ]}
                                    width={340}
                                    height={200}
                                    cx={170}
                                    cy={140}
                                    iR={80}
                                    oR={100}
                                    color="black"
                                    range={[0, 300]}
                                    sign={""}
                                />
                            </Box>
                            <Box>
                                <Typography
                                    sx={{ fontSize: '3.5em', color: updated ? 'green' : 'text.primary' }}
                                    gutterBottom
                                >
                                    {displayedData != null
                                        ? typeof displayedData === 'number'
                                        ? `${displayedData.toFixed(2)} ${displayedSign}`
                                        : displayedData
                                        : 'Waiting'}
                                </Typography>
                            </Box>
                        </Stack>    
                    )}
                </Box>
            )}
        </Box>
    );
};


const DashboardDeviceWidgetTepN = ({sensorData, timeInterval, avgEnabled, channel, tepFactor, tepType, renderSize, timestamp, text, loading}) => {

    return (
        <Box
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}        
        >
            <DashboardSensorCard sensorData={sensorData} timeInterval={timeInterval} avgEnabled={avgEnabled} channel={channel} tepFactor={tepFactor} tepType={tepType} renderSize={renderSize} timestamp={timestamp} text={text} loading={loading}/>
        </Box>
    );
}

export default DashboardDeviceWidgetTepN;
