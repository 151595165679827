import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const DashboardSensorAlarm = ({timestamp, renderSize}) => {
    
    const [size,setSize] = useState(renderSize)

    useEffect(() => {
        setSize(renderSize)
    }, [renderSize])

    return(
        <Box>
            {size==="small" && (
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <AccessTimeIcon fontSize="large"/>
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                        {timestamp}
                    </Typography>
                </Stack>
            )}
            {size==="medium" && (
                <Stack
                    display="flex"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                >
                    <AccessTimeIcon fontSize="large"/>
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                        Last Seen Timestamp
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                        {timestamp}
                    </Typography>
                </Stack>
            )}
            {size==="large" && (
                <Stack
                    display="flex"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                >
                    <AccessTimeIcon fontSize="large"/>
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                        Last Seen Timestamp
                    </Typography>
                    <Typography sx={{ fontSize: 15 }} variant="body2">
                        {timestamp}
                    </Typography>
                </Stack>
            )}
        </Box>
    )
};

const DashboardDeviceWidgetAlarm = ({timestamp, renderSize}) => {

    return (
        <div
            style={{
                display:"flex",
                height:"100%",
                width:"100%",
                justifyContent:"center",
                alignItems:"center"
            }}        
        >
                <DashboardSensorAlarm timestamp={timestamp} renderSize={renderSize}/>
        </div>
    );
}

export default DashboardDeviceWidgetAlarm;