import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Stack, Typography, AccordionDetails } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { dateFormat, dateFormatToString } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { dataResetDataHistory, selectDataHistory } from '../../../services/dataSlice';
import { sendWsRequest } from '../../../ws/webSocketServiceRequests';
import { selectUserInfo } from '../../../services/userSlice';
import { selectWebSocketInstance } from '../../../services/wsSlice';

const CustomAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 'none', // or '1px solid transparent' for a transparent border
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const CustomAccordionSummary = styled((props) => (
    <Box>
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    </Box>
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
}));

const DashboardDeviceScopeHistory = ({ sensorDataIndex, deviceData }) => {
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);

    const wsSel = useSelector(selectWebSocketInstance);
    const userInfo = useSelector(selectUserInfo);
    const historyData = useSelector(selectDataHistory);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                  await sendWsRequest(wsSel, 'getHistoryData', {clientId: userInfo.meta.company_id, sensorId: deviceData?.id, getType: "deviceById", batchNumb: 1})
                } catch (error) {
                console.error("ERROR fetching history data: " + error.message);
              }
            setLoading(false);
        };

        fetchData();

        return () => {
            dispatch(dataResetDataHistory({
                "sensorId":historyData[sensorDataIndex].id
            }));
        };
    }, [dispatch]);

    const prepareData = () => {
        return historyData[sensorDataIndex].data.slice().reverse().slice(0, 30).map(e => {
            if (e.body?.reported_at && e.body?.reported_at !== "Waiting") {
                const formattedDate = dateFormatToString(dateFormat(new Date(e.body?.reported_at)), 'dd/MM/yyyy HH:mm:ss');
                return {
                    reported_at: formattedDate,
                    values: e.values?.data[e.values?.data.length - 1]?.convertedVal,
                    radio: {
                        rssi: e?.body?.rssi,
                        snr: e?.body?.snr,
                    },
                    info: {
                        //payload: e?.body?.payload,
                        status: e?.body?.status,
                        hotspotsNum: e?.body?.hotspotsNum
                    },
                };
            }
            return null;
        }).filter(item => item !== null);
    };

    const gData = prepareData();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100%" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Stack sx={{ overflow: "auto" }} spacing={2}>
                    {gData.map((data, index) => (
                        <CustomAccordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
                            <CustomAccordionSummary sx={{ borderRadius:3 }} aria-controls="panel1d-content" id="panel1d-header">
                                <Stack
                                    direction="row"
                                    display="flex"
                                    width="100%"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Typography sx={{ fontSize: '1em' }}>{data?.reported_at}</Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={3}
                                        >
                                            <Typography sx={{ fontSize: '1em' }}>{"Data:"}</Typography>
                                            {Object.keys(data.values).map((key,index) => (
                                                <Stack direction="row" spacing={1} key={key}>
                                                    <Typography>{(deviceData?.dataInfo[index]?.label)}:</Typography>
                                                    <Typography>{(data?.values[key]).toFixed(2)}</Typography>
                                                    <Typography>{(deviceData?.dataInfo[index]?.sign)}</Typography>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Stack>
                            </CustomAccordionSummary>
                                <AccordionDetails sx={{border: 'none'}}>
                                    <Stack
                                        direction= "row"
                                        display="flex"
                                        justifyContent="space-around"
                                    >
                                        <Box>
                                            <Stack
                                                direction="column"
                                            >
                                        <Box>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>Radio</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>RSSI: {data?.radio?.rssi.toFixed(2)} SNR: {data?.radio?.snr.toFixed(2)}</Typography>
                                        </Box>                                                             
                                    </Stack>
                                </Box>
{/*
                                <Box>
                                    <Stack
                                        direction="column"
                                    >
                                        <Box>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>Info</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>Original Payload: {data?.info?.payload}</Typography>
                                        </Box>                                                              
                                    </Stack>
                                </Box>
*/}
                                <Box>
                                    <Stack
                                        direction="column"
                                    >
                                        <Box>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '1em' }}>Status</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>{data?.info?.status}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography>HotSpot Number : {data?.info?.hotspotsNum}</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </AccordionDetails>
                    </CustomAccordion>
                    ))}
                </Stack>
            )}
        </Box>
    );
}

export default DashboardDeviceScopeHistory;