import React from 'react';
import { Grid, Typography } from '@mui/material';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import { useLocation, useParams } from "react-router-dom";
import DashboardDeviceScopeVisualization from '../DashboardPanelComponents/DashboardDeviceScopeVisualization';
import { useSelector } from 'react-redux';
import { selectDevices } from '../../../services/devicesSlice';

function DashboardDeviceScope() {

    const location = useLocation();
    const obj = location.state;

    const devices = useSelector(selectDevices);
    const device = devices.find((element) => element.id === obj.deviceId);

    return (
        <Grid
            container
            direction="column"
            wrap='nowrap'
            sx={{ height: '100%' }}
        >
            <Grid 
                item
                sx={{ display: 'flex', alignItems: 'center', pl: 5, pb: 2}}
            >
                <SettingsInputAntennaIcon sx={{ fontSize: 20, mr: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {device?.name}
                </Typography>
            </Grid>
            <Grid
                item
                sx={{ overflow: 'auto' }} // Aggiunge lo scroll se necessario
            >
                <DashboardDeviceScopeVisualization obj={obj} />
            </Grid>
        </Grid>  
    );
}

export default DashboardDeviceScope;
