import { Alert, AlertTitle, Collapse } from '@mui/material';
import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error('useAlert deve essere utilizzato all\'interno di un AlertProvider');
    }
    return context;
};

export const AlertProvider = ({ children }) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertConfig, setAlertConfig] = useState({});

    const showAlert = (config) => {
        setAlertConfig(config);
        setAlertOpen(true);
    };

    const closeAlert = () => {
        setAlertOpen(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const GenAlert = () => {
        const { title, message, severity } = alertConfig;
      
        return (
          <Collapse in={alertOpen}>
            <div
              sx={{
                position: 'fixed',
                top: '50px', // Aggiorna questo valore in base alla tua esigenza
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                zIndex: 1000,
              }}
            >
              <AlertTitle>{title}</AlertTitle>
              <Alert
                onClick={handleClose}
                severity={severity}
                sx={{ mb: 2 }}
              >
                {message}
              </Alert>
            </div>
          </Collapse>
        );
    };

    const contextValue = {
        showAlert,
        closeAlert, // Aggiungere closeAlert al contesto
    };
      

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
            <GenAlert />
        </AlertContext.Provider>
    );
};
