import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function createData(user, role, vis, mod, button) {
  return { user, role, vis, mod, button };
}

const rows = [
  createData('andrea.gualandi@astreotech.com', "Administrator", "Abilitato", "Abilitato"),
  createData('alberto.marri@astreotech.com', "Administrator", "Abilitato", "Abilitato"),
  createData('jonjan.hoxha@astreotech.com', "Administrator", "Abilitato", "Abilitato"),
];

export default function DashboardDeviceScopeMembersPermissions() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Role</TableCell>
            <TableCell align="left">Visualizzazione</TableCell>
            <TableCell align="left">Modifica</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.user}</TableCell>
              <TableCell align="left">{row.role}</TableCell>
              <TableCell align="left">{row.vis}</TableCell>
              <TableCell align="left">{row.mod}</TableCell>
              <TableCell align="center">
                {row.user==="andrea.gualandi@astreotech.com" ?
                "" :
                <Stack direction="row" spacing={1}>
                  <Box display="flex">
                    {row.user==="andrea.gualandi@astreotech.com" ?
                      <Typography></Typography> :
                      <Button justifySelf="flex-end" variant="contained">EDIT</Button>
                    }
                  </Box>
                </Stack>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}