import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const DashboardDynamic3Chart = ({ data, type, avgEnabled, channel0, channel1, channel2, color0, color1, color2, labTitle0, labTitle1, labTitle2, sign, range, labels, loading, fullScreen, timeUnit, intervalMinutes }) => {
  const chartRef = useRef(null);
  const chart = useRef(null);
  const channel0Data = useRef([]);
  const channel1Data = useRef([]);
  const channel2Data = useRef([]);
  const overlayRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({x: 0, y: 0});
  const [dragEnd, setDragEnd] = useState({x: 0, y: 0});
  const [zoomActive, setZoomActive] = useState(false);

  const drawSelectionRect = () => {
    if (!isDragging || !overlayRef.current) return;

    const ctx = overlayRef.current.getContext('2d');
    if (!chart.current) return;
    const chartArea = chart.current.chartArea;
    
    const xStart = Math.max(dragStart.x, chartArea.left);
    
    const xEnd = Math.min(Math.max(dragEnd.x, chartArea.left), chartArea.right);
  
    const height = chartArea.bottom - chartArea.top;

    ctx.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
    ctx.fillStyle = `rgba(${parseInt(color0.slice(1, 3), 16)}, ${parseInt(color0.slice(3, 5), 16)}, ${parseInt(color0.slice(5, 7), 16)}, 0.1)`;
    ctx.fillRect(xStart, chartArea.top, xEnd - xStart, height);
  };

  const onMouseDown = (event) => {
    if (!event) return;

    const { offsetX, offsetY } = event;
    const {top, bottom, left, right} = chart.current.chartArea;

    if (offsetX >= left && offsetX <= right && offsetY >= top && offsetY <= bottom) {
        setIsDragging(true);
        setDragStart({ x: offsetX, y: offsetY });
    }
  };

  const onMouseMove = (event) => {
      if (!isDragging || !event) return;

      const { offsetX, offsetY } = event;
      if (offsetX !== undefined && offsetY !== undefined) {

          const endY = Math.max(Math.min(offsetY, chart.current.chartArea.bottom), chart.current.chartArea.top);
          setDragEnd({ x: offsetX, y: endY });

          drawSelectionRect();
      }
  };

  const onMouseUp = () => {
    if (isDragging) {
        setIsDragging(false);

        if (chart.current) {
          const minX = Math.max(Math.min(dragStart.x, dragEnd.x), chart.current.chartArea.left);
          const maxX = Math.min(Math.max(dragStart.x, dragEnd.x), chart.current.chartArea.right);
          const minVal = chart.current.scales.x.getValueForPixel(minX);
          const maxVal = chart.current.scales.x.getValueForPixel(maxX);

          chart.current.options.scales.x.min = minVal;
          chart.current.options.scales.x.max = maxVal;
          chart.current.update();
          setZoomActive(true);
        }

        const ctx = overlayRef.current.getContext('2d');
        ctx.clearRect(0, 0, overlayRef.current.width, overlayRef.current.height);
    }
  };

  const handleResetZoom = () => {
    if (chart.current) {
        chart.current.options.scales.x.min = undefined;
        chart.current.options.scales.x.max = undefined;
        chart.current.update();
        setZoomActive(false);
    }
  };

  useEffect(() => {
      const canvas = chartRef.current;
      
      if (!canvas) {
          return;
      }

      canvas.addEventListener('mousedown', onMouseDown);
      canvas.addEventListener('mousemove', onMouseMove);
      canvas.addEventListener('mouseup', onMouseUp);

      return () => {
        canvas.removeEventListener('mousedown', onMouseDown);
        canvas.removeEventListener('mousemove', onMouseMove);
        canvas.removeEventListener('mouseup', onMouseUp);
      };
  }, [isDragging, dragStart, dragEnd]);

  useEffect(() => {
    
    if (!loading) {

      if (data && labels) {
        const filteredData = data?.filter((item) => (item !== undefined) && (item[channel0] !== undefined));
        channel0Data.current = filteredData?.map((item) => item[channel0]);
        const filteredData1 = data?.filter((item) => (item !== undefined) && (item[channel1] !== undefined));
        channel1Data.current = filteredData1?.map((item) => item[channel1]);
        const filteredData2 = data?.filter((item) => (item !== undefined) && (item[channel2] !== undefined));
        channel2Data.current = filteredData2?.map((item) => item[channel2]);

        const dataset0_prsd = labels.map((timestamp, index) => {
          return {
            x: timestamp.toMillis(),
            y: channel0Data.current[index] // Assicurati che l'array channel0Data.current sia allineato correttamente con labels
          };
        });

        const dataset1_prsd = labels.map((timestamp, index) => {
          return {
            x: timestamp.toMillis(),
            y: channel1Data.current[index] // Assicurati che l'array channel0Data.current sia allineato correttamente con labels
          };
        });

        const dataset2_prsd = labels.map((timestamp, index) => {
          return {
            x: timestamp.toMillis(),
            y: channel2Data.current[index] // Assicurati che l'array channel0Data.current sia allineato correttamente con labels
          };
        });

        const max0 = Math.max(...channel0Data.current);
        const max1 = Math.max(...channel1Data.current);
        const max2 = Math.max(...channel2Data.current);
        const max = Math.max(max0, max1, max2);

        const min0 = Math.min(...channel0Data.current);
        const min1 = Math.min(...channel1Data.current);
        const min2 = Math.min(...channel2Data.current);
        const min = Math.min(min0, min1, min2);

        const media = (max - min) / 2;

        const minY = 0;
        const maxPercentage = Math.floor((2*(min + media)) + (2*(min + media)*(20/100)));
        const maxY = Math.max(maxPercentage, (max>maxPercentage ? (max + (max*20/100)) : 0));

        if (!chart.current) {
          chart.current = new Chart(chartRef.current, {
            type: 'line',
            data: {
              labels: labels,
              datasets: 
              [
                {
                  label: labTitle0,
                  data: dataset0_prsd,
                  fill: false,
                  borderColor: channel0Data.current.map((_, index, array) => {
                    return color0;
                  }),
                  backgroundColor: channel0Data.current.map((_, index, array) => {
                    if(index === (channel0Data.current.length - 1)) {
                      return color0 + 20;
                    } else {
                      return `rgba(${parseInt(color0.slice(1, 3), 16)}, ${parseInt(color0.slice(3, 5), 16)}, ${parseInt(color0.slice(5, 7), 16)}, 0.3)`;
                    }
                  }),
                  tension: 0.1,
                  pointRadius: 2,
                  pointHoverRadius: 5,
                  hoverBackgroundColor: color0 + 80
                },
                {
                  label: labTitle1,
                  data: dataset1_prsd,
                  fill: false,
                  borderColor: channel1Data.current.map((_, index, array) => {
                    const alpha = index === array.length - 1 ? 0.3 : 1;
                    return color1;
                  }),
                  backgroundColor: channel1Data.current.map((_, index) => {
                    if(index === (channel1Data.current.length - 1)) {
                      return color1 + 20;
                    } else {
                      return `rgba(${parseInt(color1.slice(1, 3), 16)}, ${parseInt(color1.slice(3, 5), 16)}, ${parseInt(color1.slice(5, 7), 16)}, 0.3)`;
                    }
                  }),
                  tension: 0.1,
                  pointRadius: 2,
                  pointHoverRadius: 5,
                  hoverBackgroundColor: color1 + 80
                },
                {
                  label: labTitle2,
                  data: dataset2_prsd,
                  fill: false,
                  borderColor: channel2Data.current.map((_, index, array) => {
                    const alpha = index === array.length - 1 ? 0.3 : 1;
                    return color2;
                  }),
                  backgroundColor: channel2Data.current.map((_, index) => {
                    if(index === (channel2Data.current.length - 1)) {
                      return color2 + 20;
                    } else {
                      return `rgba(${parseInt(color2.slice(1, 3), 16)}, ${parseInt(color2.slice(3, 5), 16)}, ${parseInt(color2.slice(5, 7), 16)}, 0.3)`;
                    }
                  }),
                  tension: 0.1,
                  pointRadius: 2,
                  pointHoverRadius: 5,
                  hoverBackgroundColor: color2 + 80
                }
              ],
            },
            options: {
              animation: false,
              parsing: false,
              spanGaps: true,
              responsive: true,
              maintainAspectRatio: fullScreen,
              interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
              },
              scales: {
                x: {
                  type: 'time',
                  time: {
                    tooltipFormat: 'dd/MM/yyyy HH:mm:ss.S',

                    displayFormats: {
                      millisecond: 'HH:mm:ss.S',
                      second: 'HH:mm:ss',
                      minute: 'HH:mm:ss',
                      hour: 'HH:mm:ss',
                      day: 'dd MMM',
                      week: 'dd/MM/yyyy',
                      month: 'MMM yyyy',
                      quarter: 'QQQ - yyyy',
                      year: 'yyyy',
                    }
                  },
                  ticks: {
                    source: 'auto',
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 6
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: sign
                  },
                  min: minY,
                  max: maxY,
                  normalization: true
                },
              },
              plugins: {
                decimation: {
                  enabled: true,
                  algorithm: 'lttb',
                  samples: 100,
                  threshold: 200
                },
              tooltip: {
                callbacks: {
                  title: (context) => {
                    const timestamp = context[0].parsed.x;

                    let returnObj = ""
                    let offset = 0

                    if(avgEnabled) {

                      let timeUnit="";
                      let startTimeInterval="";
                      let endTimeInterval="";

                      if(intervalMinutes===60)
                      {
                        timeUnit="hour"
                        startTimeInterval = DateTime.fromMillis(timestamp);
                        endTimeInterval = DateTime.fromMillis(timestamp).endOf(timeUnit);

                        returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                      }
                      else if(intervalMinutes===1440)
                      {
                        timeUnit="day"
                        startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);

                        returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}`;

                      }
                      else
                      {
                        timeUnit="minute"
                        offset=(intervalMinutes)
                        startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);
                        endTimeInterval = DateTime.fromMillis(timestamp).plus({ minutes: (offset) });

                        returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                      }

                    } else {
                      const timestampParsed = DateTime.fromMillis(timestamp);
                      returnObj = `${timestampParsed.toFormat('ccc, d MMM yyyy')}, ${timestampParsed.toFormat('HH:mm:ss')}`
                    }
                    
                    return returnObj;
                  },
                  label: (context) => {
                    const label = context.dataset.label || '';
                    let value = context.parsed.y;
                    value = value.toFixed(2);
                    return `${label}: ${value} ${sign}`;
                  },
                },
              }
            },
          },
        });

        chartRef.current.addEventListener('mousedown', onMouseDown);
        chartRef.current.addEventListener('mousemove', onMouseMove);
        chartRef.current.addEventListener('mouseup', onMouseUp);

      } else {
          chart.current.data.labels = labels;
          chart.current.options.maintainAspectRatio = fullScreen;
          chart.current.options.scales.x.time.unit = timeUnit;
          chart.current.options.scales.y.title.text = sign;
    
          if (chart.current.data.datasets.length > 2) {
            // Aggiorna il primo dataset
            chart.current.data.datasets[0].data = dataset0_prsd;
            chart.current.data.datasets[0].borderColor = color0;
            chart.current.data.datasets[0].pointBackgroundColor = color0;
            chart.current.data.datasets[0].backgroundColor = channel0Data.current.map((_, index, array) => {
              if(index === (channel0Data.current.length - 1)) {
                return color0 + 20;
              } else {
                return `rgba(${parseInt(color0.slice(1, 3), 16)}, ${parseInt(color0.slice(3, 5), 16)}, ${parseInt(color0.slice(5, 7), 16)}, 0.3)`;
              }
            });
            chart.current.data.datasets[0].hoverBackgroundColor = color0 + 80;
        
            // Aggiorna il secondo dataset
            chart.current.data.datasets[1].data = dataset1_prsd;
            chart.current.data.datasets[1].borderColor = color1;
            chart.current.data.datasets[1].pointBackgroundColor = color1;
            chart.current.data.datasets[1].backgroundColor = channel1Data.current.map((_, index, array) => {
              if(index === (channel1Data.current.length - 1)) {
                return color1 + 20;
              } else {
                return `rgba(${parseInt(color1.slice(1, 3), 16)}, ${parseInt(color1.slice(3, 5), 16)}, ${parseInt(color1.slice(5, 7), 16)}, 0.3)`;
              }
            });
            chart.current.data.datasets[1].hoverBackgroundColor = color1 + 80;
        
            // Aggiorna il terzo dataset
            chart.current.data.datasets[2].data = dataset2_prsd;
            chart.current.data.datasets[2].borderColor = color2;
            chart.current.data.datasets[2].pointBackgroundColor = color2;
            chart.current.data.datasets[2].backgroundColor = channel2Data.current.map((_, index, array) => {
              if(index === (channel2Data.current.length - 1)) {
                return color2 + 20;
              } else {
                return `rgba(${parseInt(color2.slice(1, 3), 16)}, ${parseInt(color2.slice(3, 5), 16)}, ${parseInt(color2.slice(5, 7), 16)}, 0.3)`;
              }
            });
            chart.current.data.datasets[2].hoverBackgroundColor = color2 + 80;
          }
    
          chart.current.options.scales.y.min = minY;
          chart.current.options.scales.y.max = maxY;

          // Aggiorna le callback dei tooltip in base alle nuove impostazioni
          chart.current.options.plugins.tooltip.callbacks = {
            title: (context) => {
              const timestamp = context[0].parsed.x;

              let returnObj = ""
              let offset = 0

              if(avgEnabled) {

                let timeUnit="";
                let startTimeInterval="";
                let endTimeInterval="";

                if(intervalMinutes===60)
                {
                  timeUnit="hour"
                  startTimeInterval = DateTime.fromMillis(timestamp);
                  endTimeInterval = DateTime.fromMillis(timestamp).endOf(timeUnit);

                  returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                }
                else if(intervalMinutes===1440)
                {
                  timeUnit="day"
                  startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);

                  returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}`;

                }
                else
                {
                  timeUnit="minute"
                  offset=(intervalMinutes)
                  startTimeInterval = DateTime.fromMillis(timestamp).startOf(timeUnit);
                  endTimeInterval = DateTime.fromMillis(timestamp).plus({ minutes: (offset) });

                  returnObj = `${startTimeInterval.toFormat('ccc, d MMM yyyy')}, ${startTimeInterval.toFormat('HH:mm:ss')} - ${endTimeInterval.toFormat('HH:mm:ss')}`;
                }

              } else {
                const timestampParsed = DateTime.fromMillis(timestamp);
                returnObj = `${timestampParsed.toFormat('ccc, d MMM yyyy')}, ${timestampParsed.toFormat('HH:mm:ss')}`
              }
              
              return returnObj;
            },
            label: (context) => {
              const label = context.dataset.label || '';
              let value = context.parsed.y;
              value = value.toFixed(2);
              return `${label}: ${value} ${sign}`;
            }
          }
          chart.current.update();
        }
      }
    }  
  }, [data, sign, range, labels, loading, channel0, channel1, channel2, color0, color1, color2, labTitle0, labTitle1, labTitle2, fullScreen]);
  
  useEffect(() => {
    return () => {
        if (chart.current) {
          setZoomActive(false);
          chart.current.destroy();
          chart.current = null;
        }
    };
  }, [type]);

  return (
    <Box sx={{ position: 'relative', display: "flex", alignItems: "center", justifyContent: 'center', width: "100%", height: "100%", overflow: 'hidden'  }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <canvas ref={chartRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 1}} />
          <canvas ref={overlayRef} style={{ position: 'absolute', top: 0, left: 0, zIndex: 2, pointerEvents: 'none' }} width={chartRef.current?.width} height={chartRef.current?.height} />
          {zoomActive && (
            <Tooltip title="Reset Zoom">
              <span>
                <IconButton
                  onClick={handleResetZoom}
                  sx={{
                    position: 'absolute',
                    top: '2rem',
                    right: '1rem',
                    zIndex: 3,
                    '& .MuiSvgIcon-root': {
                      fontSize: '1rem'
                    }
                  }}
                >
                  <ZoomOutMapIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      )}
    </Box>
  );  
};

export default DashboardDynamic3Chart;
