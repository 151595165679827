import React from 'react';
import { Grid, Icon } from '@mui/material';
import { Typography } from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

function DashboardPageReports() {
    return(
        <Grid
            container
            sx={{ height: 'auto' }} // Imposta l'altezza a 'auto'
            direction="column"
            wrap="nowrap"
        >
            <Grid 
                item
                xs={1}
                display="flex"
                alignItems="center"
            >
                <Typography
                    fontSize={20}
                    pl={5}
                    pt={4}
                    sx={{ fontWeight: 'bold' }}
                >
                    <Icon>
                        <TextSnippetIcon />
                    </Icon>
                    <span style={{ marginLeft: 8 }} />
                        REPORTS
                </Typography>
            </Grid>
            <Grid  // Visualization Panel
                item
                overflow="auto"
                xs={11}
            >
                REPORTS HERE
            </Grid>
        </Grid>  
    )
}

export default DashboardPageReports;