import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

function createData(user, role, joined, button) {
  return { user, role, joined, button };
}

const rows = [
  createData('andrea.gualandi@astreotech.com', "Administrator", "27 Avril, 2023", 24),
  createData('alberto.marri@astreotech.com', "Administrator", "27 Avril, 2023", "Administrator"),
  createData('jonjan.hoxha@astreotech.com', "Administrator", "27 Avril, 2023", 24),
];

export default function DashboardDeviceScopeMembersCompPermissions() {
  return (
    <TableContainer component={Paper}>
    <Box p={1} align="right"><Button variant="contained" startIcon={<AddIcon />}>ADD USER</Button></Box>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Role</TableCell>
            <TableCell align="left">Joined</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.user}</TableCell>
              <TableCell align="left">{row.role}</TableCell>
              <TableCell align="left">{row.joined}</TableCell>
              <TableCell align="center">
                <Box display="flex">
                    {row.user==="andrea.gualandi@astreotech.com" ?
                    <Box width="100%" justifyContent="flex-end"><Typography sx={{ fontSize: 16 }} align="right">You</Typography></Box> :
                    <Stack direction="row" width="100%" justifyContent="flex-end" spacing={1}>
                        <Button justifySelf="flex-end" variant="contained">EDIT</Button>
                        <Button justifySelf="flex-end" startIcon={<DeleteIcon />} sx={{backgroundColor:"red",color:"white"}}>RIMUOVI</Button>
                    </Stack>}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}