import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import GenOnlineCircle from '../../GeneralComponents/GenOnlineCircle';
import { dateFormat, dateFormatToString, isParsableDate } from '../../../utils/utils';

function createRow(get) {

    const convertedTimestamp = isParsableDate(get?.lastSeen)
        ?  dateFormatToString(dateFormat(get?.lastSeen))
        : 'Waiting';

    const filled =
        get?.status === 'created' ? false :
        get?.status === 'offline' ? false :
        get?.status === 'online' ? true :
        false;

    return {
        id: get?.id, // ID
        status: filled, 
        last_seen:convertedTimestamp, // Last Seen
        //primary:json?.data[0]?.values[0]?.val + json?.data[0]?.values[0]?.sign, //primary ",
        //secondary:json?.data[0]?.values[1]?.val + json?.data[0]?.values[1]?.sign, //secondary",
        //payload:json?.data[0]?.body?.payload, // Payload
        //hotspotsNumb:json?.data[0]?.body?.hotspotsNum, // Number
        //hotspotsMeta:`RSSI: ${parseFloat(json?.data[0]?.body?.hotspotsMeta[0]?.rssi,10).toFixed(2)} SNR: ${parseFloat(json?.data[0]?.body?.hotspotsMeta[0]?.snr).toFixed(2)}`, 
        //battery:"", 
        //serialNumb:"",
        //hotspotLocation:"", 
        //product:"", 
        //deviceLocation:`Latitude: ${parseFloat(dev?.location?.latitude,10)} Longitude: ${parseFloat(dev?.location?.longitude)} Altitude: ${parseFloat(dev?.location?.altitude)}`, 
    };
}

function DashboardGatewayList({gateways}) {

    const rows = []

    for (const el of gateways) {

        const gwIndex = gateways.findIndex(element => element.id === el.id)

        rows.push(createRow(gateways[gwIndex]))
    }

    const columns = [
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            type: 'actions',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <GenOnlineCircle filled={params.value} />
              ),
        },
        {
            field: 'id',
            headerName: 'Id',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'last_seen',
            headerName: 'Last Seen',
            width: 280,
            type: 'string',
            headerAlign: 'center',
            align: 'center'
        }
        // {
        //     field: 'primary',
        //     headerName: 'Primary',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'secondary',
        //     headerName: 'Secondary',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'payload',
        //     headerName: 'Payload',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'hotspotsNumb',
        //     headerName: 'Hot Spots Number',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'hotspotsMeta',
        //     headerName: 'Radio Signal',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'battery',
        //     headerName: 'Battery',
        //     width: 280,
        //     type: 'string'
        // },
        //{
        //    field: 'serialNumb',
        //    headerName: 'Serial Number',
        //    width: 280,
        //    type: 'string',
        //    headerAlign: 'center',
        //    align: 'center'
        //},
        // {
        //     field: 'hotspotLocation',
        //     headerName: 'Hot Spot Location',
        //     width: 280,
        //     type: 'string'
        // },
        //{
        //    field: 'tags',
        //    headerName: 'Tags',
        //    width: 280,
        //    type: 'string',
        //    headerAlign: 'center',
        //    align: 'center'
        //},
        // {
        //     field: 'product',
        //     headerName: 'Product',
        //     width: 280,
        //     type: 'string'
        // },
        //{
        //    field: 'productType',
        //    headerName: 'Product Type',
        //    width: 280,
        //    type: 'string',
        //    headerAlign: 'center',
        //    align: 'center'
        //},
        // {
        //     field: 'deviceLocation',
        //     headerName: 'Device Location',
        //     width: 280,
        //     type: 'string'
        // },
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     width: 280,
        //     getActions: (params) => [
        //         <GridActionsCellItem
        //             icon={<AllOutIcon />}
        //             label=""
        //             onClick={()=> {alert("")}}
        //         />,
        //         <GridActionsCellItem
        //             label="Modify Device"
        //             onClick={onHandleModify(params.id)}
        //             showInMenu
        //         />,
        //         <GridActionsCellItem
        //             label="Delete Device"
        //             onClick={onHandleDelete(params.id)}
        //             showInMenu
        //         />,
        //     ],
        //   }
    ];

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            autoHeight
            disableDensitySelector
            disableColumnMenu
            backgroundColor="blue"
            disableColumnFilter
            components={{
                Toolbar: GridToolbar,
            }}
            background="blue"
        />
    );
}

export default DashboardGatewayList;