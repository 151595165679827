import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CircularProgress, Stack } from '@mui/material';
import GenGauge from '../../../GeneralComponents/GenGauge';
import BoltIcon from '@mui/icons-material/Bolt';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import OpacityIcon from '@mui/icons-material/Opacity';
import { dateFormat, dateFormatToString, isParsableDate } from '../../../../utils/utils';

const DashboardSensorCard = ({ sensorData, avgEnabled, sign, text, renderSize, channel, iconTag, timestamp, loading }) => {
    const [size, setSize] = useState(renderSize);
    const [updated, setUpdated] = useState(false);
  
    let displayedData = 'Waiting';
  
    if (sensorData !== null && typeof sensorData !== 'undefined') {
      const lastElement = sensorData[channel];
      if (lastElement != null) {
        displayedData = lastElement;
      }
    }
  
    useEffect(() => {
      setSize(renderSize);
      setUpdated(true);
  
      const timer = setTimeout(() => {
        setUpdated(false);
      }, 1500);
  
      return () => clearTimeout(timer);
    }, [renderSize, sensorData]);
  
    useEffect(() => {
      setSize(renderSize);
    }, [renderSize]);

    const convertedTimestamp = isParsableDate(timestamp)
      ? dateFormatToString(dateFormat(new Date(timestamp)), 'dd/MM/yyyy HH:mm:ss')
      : 'Waiting';
  
    return (
      <Box width="100%" height="100%">
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box width="100%" height="100%">
            {size === 'small' && (
              <Stack
                display="flex"
                alignContent="center"
                justifyContent="space-evenly"
                direction="row"
                width="100%"
                height="100%"
              >
                <Box sx={{ width: "20%", display: 'flex', justifyContent: 'center', alignItems: 'center', color: updated ? 'green' : 'initial' }}>
                  {iconTag === 'ElectricMeterIcon' && <ElectricMeterIcon fontSize="large" />}
                  {iconTag === 'BoltIcon' && <BoltIcon fontSize="large" />}
                  {iconTag === 'DeviceThermostatIcon' && <DeviceThermostatIcon fontSize="large" />}
                  {iconTag === 'OpacityIcon' && <OpacityIcon fontSize="large" />}
                </Box>
                <Box sx={{ width: "45%", display: 'flex', justifyContent: "flex-start", alignItems: 'center' }}>
                  <Stack direction="column" justifyContent="center" alignContent="center">
                    <Typography
                      sx={{ fontSize: '0.90srem', fontWeight: 'bold', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {text}
                    </Typography>
                    <Typography
                      sx={{ fontSize: '0.65rem', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {convertedTimestamp}
                    </Typography>
                  </Stack>
                </Box>
                <Box sx={{ width: "35%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row-reverse' }}>
                  <Typography
                      sx={{ pr: 2, fontSize: '1.00rem', fontWeight: 'bold', color: updated ? 'green' : 'initial', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'right' }}
                  >
                      {displayedData != null
                      ? typeof displayedData === 'number'
                          ? (
                              <>
                                  {displayedData.toFixed(2)} 
                                  <Typography component="span" sx={{ ml: 0.25, fontSize: '0.9rem', fontWeight: 'bold' }}>
                                      {sign}
                                  </Typography>
                              </>
                              )
                          : displayedData
                      : 'waiting'}
                  </Typography>
                </Box>
              </Stack>
            )}
            {size === 'medium' && (
              <Stack>
                <Typography sx={{ fontSize: 15, color: updated ? 'green' : 'text.primary' }} gutterBottom>
                  {sensorData?.channel}
                </Typography>
                <Typography sx={{ fontSize: 20, color: updated ? 'green' : 'text.primary' }} gutterBottom>
                  {sensorData?.type}
                </Typography>
                <Typography
                  sx={{ fontSize: 35, color: updated ? 'green' : 'text.primary' }}
                  gutterBottom
                >
                  {(displayedData !== null && displayedData !== undefined)
                    ? typeof displayedData === 'number'
                      ? `${displayedData.toFixed(2)} ${sign}`
                      : displayedData
                    : 'waiting'}
                </Typography>
              </Stack>
            )}
            {size === 'large' && (
              <Stack
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                direction="column"
              >
                <Box>
                  <Typography sx={{ fontSize: 15, color: updated ? 'green' : 'text.primary' }} gutterBottom>
                    {sensorData?.channel}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: 20, color: updated ? 'green' : 'text.primary' }} gutterBottom>
                    {sensorData?.type}
                  </Typography>
                </Box>
                <Box>
                  <GenGauge
                    value={sensorData === undefined ? 0.0 : sensorData?.val}
                    data={[
                      { name: 'Start', value: sensorData === undefined ? 33.33 : sensorData?.range[1] / 3, color: '#00ff00' },
                      { name: 'Middle', value: sensorData === undefined ? 66.66 : sensorData?.range[1] / 3, color: '#0000ff' },
                      { name: 'End', value: sensorData === undefined ? 100.0 : sensorData?.range[1] / 3, color: '#ff0000' },
                    ]}
                    width={340}
                    height={200}
                    cx={170}
                    cy={140}
                    iR={80}
                    oR={100}
                    color="black"
                    range={sensorData === undefined ? [0, 100] : sensorData?.range}
                    sign=""
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: 35, color: updated ? 'green' : 'text.primary' }}
                    gutterBottom
                  >
                    {(displayedData !== null && displayedData !== undefined)
                      ? typeof displayedData === 'number'
                        ? `${displayedData.toFixed(2)} ${sign}`
                        : displayedData
                      : 'waiting'}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
        )}
      </Box>
    );
};

const DashboardDeviceWidgetCard = ({ sensorData, avgEnabled, sign, text, renderSize, channel, iconTag, timestamp, loading }) => {
  return (
    <Box
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <DashboardSensorCard
        sensorData={sensorData}
        avgEnabled={avgEnabled}
        sign={sign}
        text={text}
        renderSize={renderSize}
        channel={channel}
        iconTag={iconTag}
        timestamp={timestamp}
        loading={loading}
      />
    </Box>
  );
};

export default DashboardDeviceWidgetCard;
