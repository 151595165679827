import React from 'react';
import { Button, Grid, Icon } from '@mui/material';
import { Typography } from "@mui/material";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { sendWsRequest } from '../../../ws/webSocketServiceRequests';
import { useSelector } from 'react-redux';
import { selectWebSocketInstance } from '../../../services/wsSlice';
import { selectUserInfo } from '../../../services/userSlice';

function DashboardPageRules() {

    const ws = useSelector(selectWebSocketInstance)
    const userInfo = useSelector(selectUserInfo)

    return(
        <Grid
            container
            sx={{ height: 'auto' }} // Imposta l'altezza a 'auto'
            direction="column"
            wrap="nowrap"
        >
            <Grid 
                item
                xs={1}
                display="flex"
                alignItems="center"
            >
                <Typography
                    fontSize={20}
                    pl={5}
                    pt={4}
                    sx={{ fontWeight: 'bold' }}
                >
                    <Icon>
                        <AccountTreeIcon />
                    </Icon>
                    <span style={{ marginLeft: 8 }} />
                        RULES
                </Typography>
            </Grid>
            <Grid  // Visualization Panel
                item
                overflow="auto"
                xs={11}
            >
                <Button onClick={(() => sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: "cl-astreosrl-0080e115053a83dd", interval: "hour"}))}>
                    A
                </Button>
                <Button onClick={(() => sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: "cl-astreosrl-0080e115053a83dd", interval: "day"}))}>
                    B       
                </Button>
                <Button onClick={(() => sendWsRequest(ws, 'getData', {clientId: userInfo.meta.company_id, sensorId: "cl-astreosrl-0080e115053a83dd", interval: "week"}))}>
                    C
                </Button>
                <Button>
                    C
                </Button>
            </Grid>
        </Grid>  
    )
}

export default DashboardPageRules;