import React, { useState, useCallback } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DashboardDeviceGrid from './DashboardDeviceGrid';
import DashboardDeviceMap from './DashboardDeviceMap';
import DashboardDeviceList from './DashboardDeviceList';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const DashboardDeviceVisualization = ({ jsonData, devices }) => {
    const [value, setValue] = useState(0);
    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
    }, []);

    const TabLabel = ({ icon, label }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
            {icon}
            <Typography variant="button" sx={{ ml: 1 }}>{label}</Typography>
        </Box>
    );

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Tabs 
                onChange={handleChange} 
                value={value} 
                aria-label="device tabs" 
                sx={{ width: '100%', '.MuiTab-root': { minWidth: 120, padding: '12px 24px' } }} // Personalizzazione delle dimensioni delle tab
            >
                <Tab label={<TabLabel icon={<ListIcon />} label="List" />} />
                <Tab label={<TabLabel icon={<GridViewIcon />} label="Grid" />} />
                <Tab disabled={true} label={<TabLabel icon={<LocationOnIcon />} label="Map" />} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <DashboardDeviceList jsonData={jsonData} devices={devices} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DashboardDeviceGrid jsonData={jsonData} devices={devices} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DashboardDeviceMap jsonData={jsonData} devices={devices} />
            </TabPanel>
        </Box>
    );
}

export default DashboardDeviceVisualization;
