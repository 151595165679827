import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    showUiWsOnCloseAlert: false,
    wsReopenIsLoading: false,
  },
  reducers: {
    showUiWsOnCloseAlert: (state) => {
      state.showUiWsOnCloseAlert = true;
    },
    hideUiWsOnCloseAlert: (state) => {
      state.showUiWsOnCloseAlert = false;
    },
    setWsReopenIsLoading: (state, action) => {
      state.wsReopenIsLoading = action.payload;
    }
  },
});

export const {
  showUiWsOnCloseAlert,
  hideUiWsOnCloseAlert,
  setWsReopenIsLoading
} = uiSlice.actions;

export const selectUiWsOnCloseAlert = (state) => state.ui.showUiWsOnCloseAlert;
export const selectWsReopenIsLoading = (state) => state.ui.wsReopenIsLoading;

export default uiSlice.reducer;
