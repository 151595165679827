import { createSlice } from "@reduxjs/toolkit";

// reducer.js
const initialState = {
    timers: {}, // Cambia timers in un oggetto per memorizzare i timer con id
    // Altri campi dello stato
  };
  
  export const timerSlice = createSlice({
    name: 'timer',
    initialState: initialState,
    reducers: {
      setTimerId: (state, action) => {
        const { id, timerId } = action.payload;
        state.timers[id] = timerId; // Memorizza il timerId con l'id specifico
      },
      clearTimerId: (state, action) => {
        const { id } = action.payload;
        delete state.timers[id]; // Cancella il timerId con l'id specifico
      },
      startTimer: (state, action) => {
        const { id, callback, delay } = action.payload;
        state.timers[id] = setTimeout(callback, delay); // Memorizza il timerId con l'id specifico
      },
      stopTimer: (state, action) => {
        const { id } = action.payload;
        const timerId = state.timers[id];
        if (timerId) {
          clearTimeout(timerId); // Cancella il timer con l'id specifico
          delete state.timers[id];
        }
      },
    },
  });
  
  // Export degli action creator
  export const {
    setAccessTokenExpiration,
    setTimerId,
    clearTimerId,
    startTimer,
    stopTimer,
  } = timerSlice.actions;

  export const selectTimers = (state) => state.timer.timers
  
  // Export del reducer
  export default timerSlice.reducer;
  