import React, { createContext } from 'react';

export const QosOption = createContext([])

const DashboardDeviceScopeDownlink = () => {

  return (
    <>
      Downlink
    </>
  );
}

export default DashboardDeviceScopeDownlink;