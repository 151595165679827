import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Stack, MenuItem, CircularProgress } from '@mui/material';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider, DesktopDateTimePicker, PickersDay } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const SelectableDay = styled(PickersDay)(({ theme }) => ({
  fontWeight: 'bold',
  color: 'black',
  backgroundColor: 'lightblue',
  '&:hover': {
    backgroundColor: 'blue',
    color: 'white',
  },
}));

const NonSelectableDay = styled(PickersDay)(({ theme }) => ({
  color: 'lightgray',
}));

const GenDateTimeRangePicker = ({ handleApplyClick, handleResetClick, isLoading }) => {
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [tempStartDateTime, setTempStartDateTime] = useState(null);
  const [tempEndDateTime, setTempEndDateTime] = useState(null);
  const [avgInterval, setAvgInterval] = useState(1);
  const [error, setError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState({ apply: true, reset: true });

  const avgOptions = [
    { value: 1, label: `All Data` },
    { value: 5, label: `5 m` },
    { value: 60, label: `1 h` },
    { value: 1440, label: `1 d` }
  ];

  useEffect(() => {
    if (startDateTime && endDateTime) {
      if (
        startDateTime >= endDateTime ||
        (avgInterval === 1 && endDateTime.diff(startDateTime, 'hours').hours > 24) ||
        (avgInterval === 5 && endDateTime.diff(startDateTime, 'days').days > 7)
      ) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [startDateTime, endDateTime, avgInterval]);

  const handleStartDateTimeChange = (value) => {
    setTempStartDateTime(value);
  };

  const handleEndDateTimeChange = (value) => {
    setTempEndDateTime(value);
  };

  const handleStartDateTimeAccept = () => {
    setStartDateTime(tempStartDateTime);
    setEndDateTime(null); // Reset endDateTime quando startDateTime cambia
    setButtonDisabled({ apply: true, reset: true });
  };

  const handleEndDateTimeAccept = () => {
    setEndDateTime(tempEndDateTime);
    setButtonDisabled({ apply: startDateTime && tempEndDateTime ? false : true, reset: false });
  };

  const avgIntervalChange = (event) => {
    setAvgInterval(event.target.value);
    setStartDateTime(null);
    setEndDateTime(null);
    setTempStartDateTime(null);
    setTempEndDateTime(null);
    setButtonDisabled({ apply: true, reset: true });
  };

  const _handleApplyClick = () => {
    if (startDateTime && endDateTime && !error) {
      if (
        (avgInterval === 1 && endDateTime.diff(startDateTime, 'hours').hours <= 24) ||
        (avgInterval === 5 && endDateTime.diff(startDateTime, 'days').days <= 7) ||
        avgInterval > 5
      ) {
        handleApplyClick(startDateTime, endDateTime, avgInterval);
        setButtonDisabled({ apply: true, reset: false });
      } else {
        console.error("L'intervallo di date e orari non è valido.");
      }
    } else {
      console.error("L'intervallo di date e orari non è valido.");
    }
  };

  const _handleResetClick = () => {
    setError(false);
    setStartDateTime(null);
    setEndDateTime(null);
    setTempStartDateTime(null);
    setTempEndDateTime(null);
    handleResetClick();
    setButtonDisabled({ apply: true, reset: true });
  };

  const renderDay = (day, selectedDays, pickersDayProps) => {
    const isSelectable = !pickersDayProps.disabled;
    return isSelectable ? (
      <SelectableDay {...pickersDayProps} />
    ) : (
      <NonSelectableDay {...pickersDayProps} />
    );
  };

  return (
    <Box p={2}>
      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
        <Box alignItems="center" justifyContent="center">
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterLuxon} timeZone="Europe/Rome">
              <>
                <DesktopDateTimePicker
                  label="Interval Start"
                  value={tempStartDateTime}
                  onChange={handleStartDateTimeChange}
                  onAccept={handleStartDateTimeAccept}
                  disableFuture
                  closeOnSelect={false}
                  ampm={false}
                  disabled={isLoading || !buttonDisabled.reset}
                  format={avgInterval === 1440 ? "dd/MM/yyyy" : "dd/MM/yyyy HH:00"}
                  views={avgInterval === 1440 ? ['year', 'month', 'day'] : ['year', 'month', 'day', 'hours']}
                  minutesStep={0}
                  renderDay={renderDay}
                  slotProps={{ popper: { placement: 'left' } }}
                />
                <DesktopDateTimePicker
                  label="Interval End"
                  value={tempEndDateTime}
                  onChange={handleEndDateTimeChange}
                  onAccept={handleEndDateTimeAccept}
                  disableFuture
                  closeOnSelect={false}
                  ampm={false}
                  disabled={!startDateTime || isLoading || !buttonDisabled.reset}
                  format={avgInterval === 1440 ? "dd/MM/yyyy" : "dd/MM/yyyy HH:00"}
                  views={avgInterval === 1440 ? ['year', 'month', 'day'] : ['year', 'month', 'day', 'hours']}
                  minutesStep={0}
                  renderDay={renderDay}
                  slotProps={{ popper: { placement: 'right' } }}
                  minDateTime={startDateTime}
                  maxDateTime={
                    avgInterval === 1 && startDateTime
                      ? startDateTime.plus({ hours: 24 })
                      : avgInterval === 5 && startDateTime
                      ? startDateTime.plus({ days: 7 })
                      : null
                  }
                />
              </>
            </LocalizationProvider>
            <TextField
              onChange={avgIntervalChange}
              select
              value={avgInterval}
              label="Avg Interval"
              sx={{ width: '14em' }}
              disabled={isLoading || !buttonDisabled.reset}
            >
              {avgOptions.map((avgOption) => (
                <MenuItem key={avgOption.label} value={avgOption.value}>
                  {avgOption.label}
                </MenuItem>
              ))}
            </TextField>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                disabled={error || isLoading || buttonDisabled.apply}
                onClick={_handleApplyClick}
                sx={{ mr: 2 }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Apply'}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={error || isLoading || buttonDisabled.reset}
                onClick={_handleResetClick}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Reset'}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default GenDateTimeRangePicker;
