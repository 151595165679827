import { Box, Typography } from '@mui/material';
import React from 'react';

// Hook or Class
// import ClassMqtt from './components/Class/'

function PageNotFound() {
  return (
    <Box
        height="100vh"
        width="100%"
        display="flex"
        justifyContent="center"
        textAlign="center"
    >
        <Typography variant="h2">
            404 NOT FOUND
        </Typography>
    </Box>
  );
}

export default PageNotFound;