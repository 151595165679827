import React from 'react';
import DashboardChartDummy from '../components/DashboardPageComponents/DashboardCharts/DashboardChartDummy';
// Hook or Class
// import ClassMqtt from './components/Class/'


function TestPage() {
  return (
    <div className="App">
      <DashboardChartDummy/>
    </div>
  );
}

export default TestPage;