import { sendWsMessageWait } from "./webSocket";
import { wsUtilsPrepareWsMeta } from "./webSocketUtils";

export async function sendWsRequest(ws, service, message) {

  //const meta = wsUtilsPrepareWsMeta();

  const outMessage = {
    service: `${service}`,
    message: message,
    //wsMeta: meta
    // Altri dati relativi all'azione
  };

  //sendWsMessage(ws,JSON.stringify(outMessage));
  try {
    const response = await sendWsMessageWait(ws, outMessage);
  } catch (error) {
    console.error('Errore durante l\'invio del messaggio WebSocket:', error);
  } finally {
  }
}