import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    wsInstance:null
};

export const wsSlice = createSlice({
    name: 'ws',
    initialState: initialState,
    reducers: {
        wsSetWebSocketInstance : (state, action) => {
            state.wsInstance = action.payload;
        }
    }
});
 
export const {
    wsSetWebSocketInstance,
} = wsSlice.actions;

export const selectWebSocketInstance = (state) => state.ws.wsInstance

export default wsSlice.reducer;
