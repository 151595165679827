/* eslint-disable no-shadow */
import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const RADIAN = Math.PI / 180;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path d={`M${xba},${yba}L${xbb},${ybb},L${xp},${yp},L${xba},${yba}`} stroke="#none" fill={color} />,
  ];
};

const GenGauge = ({value, width, height, data, cx, cy, iR, oR, color, range, sign}) => {

    const minVal = (range===undefined ? 0 : range[0])
    const maxVal = (range===undefined ? 100 : range[1])
    const val = (value===undefined ? 0 : value)
    const dat = data
    const signn = (sign===undefined ? "" : sign)

    return (
      <PieChart width={width} height={height}>
        <Pie
          dataKey="value"
          startAngle={190}
          endAngle={-10}
          data={data}
          cx={cx}
          cy={cy}
          labelLine={{ stroke: 'grey', strokeWidth: 1 }}
          paddingAngle={5}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
            {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
        </Pie>
        <text x={cx - oR - oR/6} y={cy + cy/5} fontSize={18} textAnchor="end">{minVal} {signn}</text>
        <text x={cx} y={cy - oR - iR/8} fontSize={18} textAnchor="middle">{(maxVal-minVal)/2} {signn}</text>
        <text x={cx + oR + oR/6} y={cy + cy/5} fontSize={18} textAnchor="start">{maxVal} {signn}</text>
        {needle(val, dat, cx, cy, iR, oR, color)}
      </PieChart>
    );
}

export default GenGauge;