import React from 'react';
import { Route, Routes } from "react-router-dom";
import DashboardPageIntegrations from './components/DashboardPageComponents/DashboardPageTabs/DasboardPageIntegrations';
import DashboardDevicePanel from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageDevicePanel';
import DashboardPageMembers from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageMembers';
import DashboardPageReports from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageReports';
import DashboardPageRules from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageRules';
import DashboardPageWorkspace from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageWorkspace';
import DashboardPage from './pages/DashboardPage';
import TestPage from './pages/TestPage';
import DashboardDeviceScope from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageDeviceScope';
import PageNotFound from './pages/NotFound';
import AuthenticationPage from './pages/AuthenticationPage';
import LoadingPage from './pages/LoadingPage';
import ChartPage from './pages/ChartPage';
import { Box } from '@mui/material';
import DashboardPageConcentratorPanel from './components/DashboardPageComponents/DashboardPageTabs/DashboardPageConcentratorPanel';

function App() {

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  return (
    <Box>
      {(isMobile && process.env.REACT_APP_ENV!=="test") ? (
        <div>
          <p>This application is currently optimized for use on desktop computers 
            and is not compatible with mobile devices. We apologize for any inconvenience 
            and invite you to return and access the app from a desktop computer.</p>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<AuthenticationPage />} />
          <Route path="/Loading" element={<LoadingPage />} />
          <Route path="/DashboardPage" element={<DashboardPage />} >
            <Route path="/DashboardPage/DevicePanel" element={<DashboardDevicePanel />} />
            <Route path="/DashboardPage/ConcentratorPanel" element={<DashboardPageConcentratorPanel />} />
            <Route path="/DashboardPage/DashboardDeviceScope/" element={<DashboardDeviceScope />}/>
            <Route path="/DashboardPage/Reports" element={<DashboardPageReports />} />
            <Route path="/DashboardPage/Members" element={<DashboardPageMembers />} />
            <Route path="/DashboardPage/Rules" element={<DashboardPageRules />} />
            <Route path="/DashboardPage/Workspace" element={<DashboardPageWorkspace />} />
            <Route path="/DashboardPage/Integrations" element={<DashboardPageIntegrations />} />
            <Route path="/DashboardPage/ChartPage/" element={<ChartPage />} />
          </Route>
          <Route path="/TestPage" element={<TestPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </Box>
  );
}

export default App;
