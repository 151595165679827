import React from 'react';
import { SvgIcon } from '@mui/material';

const CustomSignalCellularAltIcon = ({level}) => {

    switch (level) {
        case 0:
            return (
                <SvgIcon>
                    <rect x="0" y="18" width="4" height="6" fill="#a8b3ab" />
                    <rect x="6" y="12" width="4" height="12" fill="#a8b3ab" />
                    <rect x="12" y="6" width="4" height="18" fill="#a8b3ab" />
                    <rect x="18" y="0" width="4" height="24" fill="#a8b3ab" />
                </SvgIcon>
            );
        case 1:
            return (
                <SvgIcon>
                    <rect x="0" y="18" width="4" height="6" fill="#E76264" />
                    <rect x="6" y="12" width="4" height="12" fill="#a8b3ab" />
                    <rect x="12" y="6" width="4" height="18" fill="#a8b3ab" />
                    <rect x="18" y="0" width="4" height="24" fill="#a8b3ab" />
                </SvgIcon>
            );
        case 2:
            return (
                <SvgIcon>
                    <rect x="0" y="18" width="4" height="6" fill="#ECC94C" />
                    <rect x="6" y="12" width="4" height="12" fill="#ECC94C" />
                    <rect x="12" y="6" width="4" height="18" fill="#a8b3ab" />
                    <rect x="18" y="0" width="4" height="24" fill="#a8b3ab" />
                </SvgIcon>
            );
        case 3:
            return (
                <SvgIcon>
                    <rect x="0" y="18" width="4" height="6" fill="#108CFD" />
                    <rect x="6" y="12" width="4" height="12" fill="#108CFD" />
                    <rect x="12" y="6" width="4" height="18" fill="#108CFD" />
                    <rect x="18" y="0" width="4" height="24" fill="#a8b3ab" />
                </SvgIcon>
            );
        case 4:
            return (
                <SvgIcon>
                    <rect x="0" y="18" width="4" height="6" fill="#40CD8A" />
                    <rect x="6" y="12" width="4" height="12" fill="#40CD8A" />
                    <rect x="12" y="6" width="4" height="18" fill="#40CD8A" />
                    <rect x="18" y="0" width="4" height="24" fill="#40CD8A" />
                </SvgIcon>
            );
        default:
            return (
                <SvgIcon>
                    <rect x="0" y="18" width="4" height="6" fill="#40CD8A" />
                    <rect x="6" y="12" width="4" height="12" fill="#40CD8A" />
                    <rect x="12" y="6" width="4" height="18" fill="#40CD8A" />
                    <rect x="18" y="0" width="4" height="24" fill="#40CD8A" />
                </SvgIcon>
            );
    }
};

export default CustomSignalCellularAltIcon;