import React from 'react';
import { Box, Grid, Icon } from '@mui/material';
import { Typography } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import DashboardDeviceScopeMembers from '../DashboardPanelComponents/DashboardDeviceScopeMembers';

function DashboardPageMembers() {
    return(
        <Grid
            container
            sx={{ height: 'auto' }} // Imposta l'altezza a 'auto'
            direction="column"
            wrap="nowrap"
        >
            <Grid 
                item
                xs={1}
                display="flex"
                alignItems="center"
            >
                <Typography
                    fontSize={20}
                    pl={5}
                    pt={4}
                    sx={{ fontWeight: 'bold' }}
                >
                    <Icon>
                        <PeopleIcon />
                    </Icon>
                    <span style={{ marginLeft: 8 }} />
                        MEMBERS
                </Typography>
            </Grid>
            <Grid  // Visualization Panel
                item
                overflow="auto"
                xs={11}
            >
                <Box pt={2}>
                    <DashboardDeviceScopeMembers />
                </Box>
            </Grid>
        </Grid>  
    )
}

export default DashboardPageMembers;