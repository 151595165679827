import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DashboardGeneralConfiguration from './DashboardDeviceConfigurationSections/DashboardGeneralConfiguration';
import DashboardLoRaWANConfiguration from './DashboardDeviceConfigurationSections/DashboardLoRaWANConfiguration';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DashboardDeviceScopeConfiguration = ({ deviceData }) => {
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = useCallback((panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }, []);

    return (
        <Stack sx={{ overflow: 'auto' }}>
            <CustomAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <CustomAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
                    <Typography pl={1}>General Information</Typography>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <DashboardGeneralConfiguration deviceData={deviceData}/>
                </CustomAccordionDetails>
            </CustomAccordion>
            <CustomAccordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <CustomAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
                    <Typography pl={1}>LoRaWAN Information</Typography>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                    <DashboardLoRaWANConfiguration deviceData={deviceData}/>
                </CustomAccordionDetails>
            </CustomAccordion>
        </Stack>
    );
}

export default DashboardDeviceScopeConfiguration;
