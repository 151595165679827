import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress, Stack } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { dateFormat, dateFormatToString, isParsableDate } from '../../../../utils/utils';

const DashboardSensorTimestamp = ({timestamp, avgEnabled, renderSize, loading}) => {
    
    const [size,setSize] = useState(renderSize)

    useEffect(() => {
        setSize(renderSize)
    }, [renderSize])

    const convertedTimestamp = isParsableDate(timestamp)
        ? dateFormatToString(dateFormat(new Date(timestamp)),'dd/MM/yyyy HH:mm:ss')
        : 'Waiting';

    return(
        <Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {size==="small" && (
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <AccessTimeIcon fontSize="large"/>
                            <Typography sx={{ fontSize: 15 }} variant="body2">
                                {timestamp != null
                                    ? `${convertedTimestamp}`
                                    : 'Waiting'}
                            </Typography>
                        </Stack>
                    )}
                    {size==="medium" && (
                        <Stack
                            display="flex"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <AccessTimeIcon fontSize="large"/>
                            <Typography sx={{ fontSize: 15 }} variant="body2">
                                Last Seen Timestamp
                            </Typography>
                            <Typography sx={{ fontSize: 15 }} variant="body2">
                                {timestamp != null
                                    ? `${convertedTimestamp}`
                                    : 'Waiting'}
                            </Typography>
                        </Stack>
                    )}
                    {size==="large" && (
                        <Stack
                            display="flex"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <AccessTimeIcon fontSize="large"/>
                            <Typography sx={{ fontSize: 15 }} variant="body2">
                                Last Seen Timestamp
                            </Typography>
                            <Typography sx={{ fontSize: 15 }} variant="body2">
                                {timestamp != null
                                    ? `${convertedTimestamp}`
                                    : 'Waiting'}
                            </Typography>
                        </Stack>
                    )}
                </Box>
            )}
        </Box>
    )
};

const DashboardDeviceWidgetTimestamp = ({timestamp, avgEnabled, renderSize, loading}) => {

    return (
        <Box
            style={{
                display:"flex",
                height:"100%",
                width:"100%",
                justifyContent:"center",
                alignItems:"center"
            }}        
        >
                <DashboardSensorTimestamp timestamp={timestamp} avgEnabled={avgEnabled} renderSize={renderSize} loading={loading}/>
        </Box>
    );
}

export default DashboardDeviceWidgetTimestamp;