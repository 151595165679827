import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import GenGauge from '../../../GeneralComponents/GenGauge';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';

const DashboardSensorCard = ({sensorData, renderSize, timestamp}) => {
    
    const [size, setSize] = useState(renderSize)

    useEffect(() => {
        setSize(renderSize)
    }, [renderSize])

    return(
        <Box width="100%">
            {size==="small" && (
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    direction="row"
                    spacing={3}
                    width="100%"
                >
                    <Box sx={{ pt: 1, pl: 2 }} width="5%" justifyContent="flex-start">
                        <SpeedOutlinedIcon fontSize="large"/>
                    </Box>
                    <Box justifyContent="center">
                        <Stack direction="column">
                            <Box>
                                <Typography sx={{ fontSize: 18, fontWeight: 'bold'}} color="text.primary" >
                                    Consumo Orario
                                </Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 12 }} color="text.primary" >
                                    {timestamp}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box
                        justifyContent="flex-end"
                        sx={{ pr: 2 }}
                    >
                        <Typography sx={{ fontSize: 23, fontWeight: 'bold', pt:1 }} color="text.primary" gutterBottom>
                            {(sensorData?.val/1000).toFixed(3)} kWh
                        </Typography>
                    </Box>
                </Stack>
            )}
            {size==="medium" && (
                <Stack>
                    <Typography sx={{ fontSize: 15 }} color="text.primary" gutterBottom>
                        kW Orari
                    </Typography>
                    <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
                        {sensorData?.type}
                    </Typography>
                    <Typography sx={{ fontSize: 35 }} color="text.primary" gutterBottom>
                        {(sensorData?.val/1000).toFixed(3)} kWh
                    </Typography>
                </Stack>        
            )}
            {size==="large" && (
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    direction="column"                 
                >
                    <Box>
                        <Typography sx={{ fontSize: 15 }} color="text.primary" gutterBottom>
                            kW Orari
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
                            {sensorData?.type}
                        </Typography>
                    </Box>
                    <Box>
                        <GenGauge 
                            value={sensorData===undefined ? 0.00 : (sensorData?.val)}
                            data={[
                                { name: 'Start', value: (sensorData===undefined ? 33.33 : (sensorData?.range[1]/3)), color: '#00ff00' },
                                { name: 'Middle', value: (sensorData===undefined ? 66.66 : (sensorData?.range[1]/3)), color: '#0000ff' },
                                { name: 'End', value: (sensorData===undefined ? 100.00 : (sensorData?.range[1]/3)), color: '#ff0000' },
                            ]}
                            width={340}
                            height={200}
                            cx={170}
                            cy={140}
                            iR={80}
                            oR={100}
                            color="black"
                            range={[0,300]}
                            sign={""}
                        />
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: 35 }} color="text.primary" gutterBottom>
                            {(sensorData?.val/1000).toFixed(3)} kWh
                        </Typography>
                    </Box>
                </Stack>    
            )}
        </Box>
    )
};

const DashboardDeviceWidgetPh = ({sensorData, renderSize, timestamp}) => {

    return (
        <Box
            style={{
                display:"flex",
                height:"100%",
                width:"100%",
                justifyContent:"center",
                alignItems:"center"
            }}        
        >
            <DashboardSensorCard sensorData={sensorData} renderSize={renderSize} timestamp={timestamp}/>
        </Box>
    );
}

export default DashboardDeviceWidgetPh;