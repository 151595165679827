import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress, Stack } from '@mui/material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CustomSignalCellularAltIcon from '../../../GeneralComponents/GenRadioIcon';

const DashboardSensorRadio = ({ sensorData, renderSize, loading }) => {
    let rssiFloat = sensorData?.rssi ? parseFloat(sensorData.rssi).toFixed(2) : null;
    let snrFloat = sensorData?.snr ? parseFloat(sensorData.snr).toFixed(2) : null;

    const [size, setSize] = useState(renderSize);
    const [updated, setUpdated] = useState(false);
    const [radioStrength, setRadioStrength] = useState({ level: 0, label: "Waiting" })

    useEffect(() => {

        const updatedSignalStrength = radioStrength;

        if(rssiFloat===null)
        {
            // Do nothing
        }
        else {
            if(rssiFloat >= -110)
            {
                //Excellent
                updatedSignalStrength.level = 4;
                updatedSignalStrength.label = "Excellent";
            } else if( rssiFloat >= -120 && rssiFloat < -110 ) {
                //Good
                updatedSignalStrength.level = 3;
                updatedSignalStrength.label = "Good";
            } else if( rssiFloat >= -127 && rssiFloat < -120 ) {
                //Fair
                updatedSignalStrength.level = 2;
                updatedSignalStrength.label = "Fair";
            } else {
                //Weak
                updatedSignalStrength.level = 1;
                updatedSignalStrength.label = "Weak";
            }
        }

        setRadioStrength(updatedSignalStrength);

        setSize(renderSize);
        setUpdated(true);

        const timer = setTimeout(() => {
            setUpdated(false);
        }, 1500);

        return () => clearTimeout(timer);
    }, [renderSize, sensorData]);

    const updatedStyle = updated ? { color: 'green' } : {};

    return (
        <Box>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {size === "small" && (
                        <Stack
                            display="flex"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <CustomSignalCellularAltIcon level={radioStrength.level} fontSize="large" sx={updatedStyle} />
                            <Typography sx={{ fontSize: 15, ...updatedStyle }} variant="body2">
                                {`Signal Strength: ${radioStrength.label}`}
                            </Typography>
                        </Stack>
                    )}
                    {size === "medium" && (
                        <Stack
                            display="flex"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CustomSignalCellularAltIcon level={radioStrength.level} fontSize="large" sx={updatedStyle} />
                            <Typography sx={{ fontSize: 15, ...updatedStyle }} variant="body2">
                                Radio
                            </Typography>
                            <Typography sx={{ fontSize: 15, ...updatedStyle }} variant="body2">
                                {`Signal Strength: ${radioStrength.label}`}
                            </Typography>
                        </Stack>
                    )}
                    {size === "large" && (
                        <Stack
                            display="flex"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CustomSignalCellularAltIcon level={radioStrength.level} fontSize="large" sx={updatedStyle} />
                            <Typography sx={{ fontSize: 15, ...updatedStyle }} variant="body2">
                                Radio
                            </Typography>
                            <Typography sx={{ fontSize: 15, ...updatedStyle }} variant="body2">
                                {`Signal Strength: ${radioStrength.label}`}
                            </Typography>
                        </Stack>
                    )}
                </Box>
            )}
        </Box>
    );
};

const DashboardDeviceWidgetRadio = ({ sensorData, renderSize, loading }) => {
    return (
        <Box
            style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <DashboardSensorRadio sensorData={sensorData} renderSize={renderSize} loading={loading} />
        </Box>
    );
}

export default DashboardDeviceWidgetRadio;
