import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const AlertDialog = ({dialogTitle, dialogContextText,buttonLabel0,buttonLabel1,open,handleButton0,handleClose}) => {
  
    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContextText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleButton0}>{buttonLabel0}</Button>
                <Button onClick={handleClose} autoFocus>{buttonLabel1}</Button>
            </DialogActions>
            </Dialog>
        </Box>
    );
  }

export default AlertDialog;