import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithNavigate= ({ children }) => {
    const navigate = useNavigate()

    //const domain = process.env.REACT_APP_AUTH0_DOMAIN || "dev-03v7gzczvcpy8glw.us.auth0.com";
    const domain = process.env.REACT_APP_AUTH0_DOMAIN || "dev-0jvewp8cjc1noj0q.eu.auth0.com";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "aSSwG6GjjIqZWS3td0bD4LhEVZElmmJQ";
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL || `http://127.0.0.1:3000`;
  
    const onRedirectCallback = (appState) => {
      //navigate(appState?.returnTo || window.location.pathname);
    };
  
    if (!(domain && clientId && redirectUri)) {
      return null;
    }
  
    return (
      <Auth0Provider 
        domain = {domain}
        clientId = {clientId}
        useRefreshTokens = {false}
        authorizationParams={{
          redirect_uri: redirectUri,
        }}
        onRedirectCallback = {onRedirectCallback}
      >
          {children}
      </Auth0Provider>
      
  );
};

export { Auth0ProviderWithNavigate };
