import { createSlice } from '@reduxjs/toolkit';
import { dataUpdateData } from './dataSlice';

const temp_gateways_count = 0

const temp_gateways_vect = []

const initialState = {
    gateways: temp_gateways_vect,
    count: temp_gateways_count,
};

export const gatewaysSlice = createSlice({
    name: 'gateways',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        gatewaysAddDevice: {
            reducer: (state, action) => {

                const oldData = state.gateways;
                
                const newData = [...oldData, action.payload];
                state.gateways = newData;
                state.count += 1;
            },
            prepare: (json) => {

                //See if some parsing makes sense

                return { payload: json }
            }
        },
        gatewaysAddAllGateways: {
            reducer: (state, action) => {
                const newData = [...action.payload];
                state.gateways = newData;
                state.count += action.payload.length;
            },
            prepare: (json) => {

                //See if some parsing makes sense

                return { payload: json };
            }
        },
        gatewaysDeleteDevice: {
            reducer: (state, action) => {

                const oldData = state.gateways;

                const index = oldData.findIndex(element => element.id === action.payload);
                
                if(index >= 0)
                {                
                    let newArray = [...state.gateways];
                    newArray.splice(index, 1);
                    state.gateways = newArray;
                    
                    state.count -= 1;
                }
            },
            prepare: (delGatewayId) => {

                //See if some parsing makes sense

                return { payload: delGatewayId };
            }
        },
        gatewaysModifyDevice: {
            reducer: (state, action) => {

                const oldData = state.gateways;

                const index = oldData.findIndex(element => element.id === action.payload.id);

                if(index >= 0)
                {
                    let newArray = [...state.gateways];
                    newArray[index] = action.payload;
                    state.gateways = newArray;
                }
            },
            prepare: (modGatewayId) => {

                //See if some parsing makes sense

                return { payload: modGatewayId };
            }
        },
        gatewaysModifyStatus: (state, action) => {

            const status = action?.payload?.status?.status;
            const lastSeen = action?.payload?.status?.lastSeen;

            const index = state.gateways.findIndex((element) => element.id === action.payload.id);

            if(index >= 0)
            {
                state.gateways[index].status = status;
                state.gateways[index].lastSeen = new Date(lastSeen);
            }
        }
    },
    extraReducers: (builder) => { 
        builder
            .addCase(dataUpdateData, (state, action) => {
                const oldData = state.gateways
                const gwId = action?.payload?.deviceMeta?.gwId;
                const lastSeen = action?.payload?.deviceMeta?.lastSeen || 0;

                const index = oldData.findIndex(element => element.id === gwId);
                
                if(index >= 0)
                {
                    let newArray = [...state.gateways];
                    newArray[index].status = "online";
                    newArray[index].lastSeen = new Date(lastSeen);
                    state.gateways = newArray;
                }
            })
        }
});
 
export const {  
                gatewaysAddGateway,
                gatewaysAddAllGateways,
                gatewaysDeleteGateway,
                gatewaysModifyGateway,
                updateAllCallback,
                gatewaysModifyStatus
            } = gatewaysSlice.actions;

export const selectGateways = (state) => state.gateways.gateways;
export const selectGatewaysCount = (state) => state.gateways.count;

export default gatewaysSlice.reducer;
