import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Box } from '@mui/material';

// TODO Google Maps Api

const defaultProps = {
    center: {lat: 40.73, lng: -73.93}, 
    zoom: 12
 }
 //the center coordinates are NYC. 
 //I chose 12 as the zoom because it didn’t seem too far away or too close.
 //The higher the number you choose, the more you zoom in on the map.

function DashboardDeviceMap({jsonData,devices}) {

    return (
        <Box>
            {/*<GoogleMapReact
                bootstrapURLKeys={{
                    key: YOUR_API_KEY, 
                    language: 'en'
                }}
                defaultCenter={this.props.center}
                center={this.state.center}
                defaultZoom={this.props.zoom}
                onChildMouseEnter={this.onChildMouseEnter}
                onChildMouseLeave={this.onChildMouseLeave}
            />*/}
        </Box>
    )
}
  
export default DashboardDeviceMap;