import { setWsConnectionUuid } from "./webSocket";
import { wsSetWebSocketInstance } from "../services/wsSlice";
import { dataUpdateAllData, dataUpdateAllHistroyData, dataUpdateData } from "../services/dataSlice";
import { devicesAddAllDevices, devicesModifyStatus } from "../services/devicesSlice";
import { gatewaysAddAllGateways, gatewaysModifyStatus } from "../services/gatewaysSlice";
import { hideUiWsOnCloseAlert, setWsReopenIsLoading, showUiWsOnCloseAlert } from "../services/uiSlice";

function onWsOnOpen() {
    //updateState('ws', { isLoading: false });
}

function onWsOnClose(dispatch) {
    dispatch(showUiWsOnCloseAlert());
}

function onWsOnMessage(dispatch, message, ws) {
    const jsonMessage = JSON.parse(message);
    switch(jsonMessage?.service)
    {
        case 'connectionId':
            // Update UUID
            setWsConnectionUuid(ws, jsonMessage?.message?.connectionId);
            dispatch(wsSetWebSocketInstance(ws));
            dispatch(setWsReopenIsLoading(false));
            dispatch(hideUiWsOnCloseAlert());
            break;
        case 'updateData':
            dispatch(dataUpdateData(jsonMessage?.message));
            break;
        case 'updateStatus':
            dispatch(devicesModifyStatus(jsonMessage?.message));
            break;
        case 'updateAllData':
            dispatch(dataUpdateAllData(jsonMessage?.message));
            break;
        case 'updateAllHistoryData':
            if (process.env.REACT_APP_ENV  !== 'production') {
                console.log("DATA HISTORY RECEIVED");
            }
            dispatch(dataUpdateAllHistroyData(jsonMessage?.message));
            //updateState('history', { isLoading: false });
            break;
        case 'getDevicesByClientId':
            if (process.env.REACT_APP_ENV  !== 'production') {
                console.log("DEVICES RECEIVED");
            }
            dispatch(
                devicesAddAllDevices(jsonMessage?.message)
            );
            //updateState('device', { isLoading: false });
            break;
        case 'getGatewaysByClientId':
            if (process.env.REACT_APP_ENV  !== 'production') {
                console.log("GATEWAYS RECEIVED");
            }
            dispatch(
                gatewaysAddAllGateways(jsonMessage?.message)
            );
            //updateState('gateway', { isLoading: false });
            break;
        case 'updateGwStatus':
            dispatch(gatewaysModifyStatus(jsonMessage?.message));
            break;
        default:
            break;
    }
}

function onWsOnError( dispatch, error ) {
    console.log("Ws Error : " + JSON.stringify(error));
}

export { 
    onWsOnOpen,
    onWsOnClose,
    onWsOnMessage,
    onWsOnError };
  
