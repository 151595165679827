import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo, selectUserMeta, selectUserToken, userSetUserInfo, userSetUserMetadata, userSetUserToken } from "../services/userSlice";
import { devicesAddAllDevices } from "../services/devicesSlice";
import { widgetsAddAllWidgets } from "../services/widgetsSlice";
import { CircularProgress, Grid, Typography } from '@mui/material';
import { WebSocketModule } from "../ws/webSocket";
import useTokenExpireTimer from "../timers/tokenExpireTimer";
import { readFromDatabase } from "../db/dbAccessFunctions";
import { gatewaysAddAllGateways } from "../services/gatewaysSlice";
import { onWsOnClose, onWsOnError, onWsOnMessage, onWsOnOpen } from "../ws/wsAppCallbacks";
import { useCustomNavigate } from "../navigation/customNavigate";

function getUrlForEnvironment(base, baseTest, path, companyId) {
  let url;
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      url = `https://${base}/${path}/${companyId}`;
      break;
    case 'test':
      url = `https://${baseTest}/${path}/${companyId}`;
      break;
    default: // Assume 'development' or unknown environment
      url = `http://${baseTest}/${path}/${companyId}`;
  }
  return url;
}

const LoadingPage = () => {
  const { 
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithPopup,
    logout
  } = useAuth0();

  const {
    startTokenExpireTimer,
    stopTokenExpireTimer,
    resetTokenExpireTimer,
    refreshTokenExpireTimer,
  } = useTokenExpireTimer();

  const initialState = {
    device: { isLoading: true, isError: false },
    widget: { isLoading: true, isError: false },
    user: { isLoading: true, isError: false },
    ws: { isLoading: true, isError: false },
    history: { isLoading: false, isError: false },
    gateway: { isLoading: true, isError: false },
  };
  
  const [state, setState] = useState(initialState);
  
  // Function to update individual parts of the state
  const updateState = (key, newState) => {
    setState(prev => ({ ...prev, [key]: { ...prev[key], ...newState } }));
  };
  
  const accToken = useSelector(selectUserToken);
  const userMeta = useSelector(selectUserMeta);
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const customNavigate = useCustomNavigate();

  let logoutUrl = process.env.REACT_APP_AUTH0_LOGOUT_CALLBACK_URL || "http://127.0.0.1:3000";

  const handleLogoutDashboard = async () => {
    localStorage.removeItem('persist:root');
    await logout({ 
      logoutParams: {
        returnTo: logoutUrl
      }
    });
  };
  
  const expiredAccessTokenCallback = () => {
    window.alert("La sessione è scaduta. Effettua nuovamente l'accesso.");
    handleLogoutDashboard();
  };

  const ENDPOINT_BASE = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';
  const ENDPOINT_BASE_TEST = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';
  const AUTH0_CLAIM_NAMESPACE = process.env.AUTH0_CLAIM_NAMESPACE || 'https://jwt_app_access_capp.com';
  const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || "dev-0jvewp8cjc1noj0q.eu.auth0.com";

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated || state.user.isLoading || state.ws.isLoading) return;
  
      try {
        const devicesUrl = getUrlForEnvironment(ENDPOINT_BASE, ENDPOINT_BASE_TEST, 'devices', userInfo.meta.company_id);
        const widgetsUrl = getUrlForEnvironment(ENDPOINT_BASE, ENDPOINT_BASE_TEST, 'widgets', userInfo.meta.company_id);
        const gatewaysUrl = getUrlForEnvironment(ENDPOINT_BASE, ENDPOINT_BASE_TEST, 'gateways', userInfo.meta.company_id);

        if (!state.user.isLoading) {
          const [devicesResponse, widgetsResponse, gatewaysResponse] = await Promise.all([
            readFromDatabase(devicesUrl, accToken),
            readFromDatabase(widgetsUrl, accToken),
            readFromDatabase(gatewaysUrl, accToken),
          ]);
  
          if (!devicesResponse.ok) throw new Error('Errore durante il recupero dei dati dei dispositivi');
          if (!widgetsResponse.ok) throw new Error('Errore durante il recupero dei dati dei widget');
          if (!gatewaysResponse.ok) throw new Error('Errore durante il recupero dei dati dei gateway');
  
          const [devicesData, widgetsData, gatewaysData] = await Promise.all([
            devicesResponse.json(),
            widgetsResponse.json(),
            gatewaysResponse.json(),
          ]);
  
          dispatch(devicesAddAllDevices(devicesData));
          dispatch(widgetsAddAllWidgets(widgetsData));
          dispatch(gatewaysAddAllGateways(gatewaysData));
  
          updateState('device', { isLoading: false });
          updateState('widget', { isLoading: false });
          updateState('gateway', { isLoading: false });
  
          if (process.env.REACT_APP_ENV !== 'production') {
            console.log("Devices, Widgets, and Gateways Loaded");
          }
        }
  
      } catch (error) {
        console.error("ERROR fetching data: ", error);
        updateState('device', { isLoading: true, isError: true });
        updateState('widget', { isLoading: true, isError: true });
        updateState('gateway', { isLoading: true, isError: true });
      }
    };
  
    fetchData();
  }, [isAuthenticated, state.user.isLoading, state.ws.isLoading]);
  

  useEffect(() => {
    const getUserData = async () => {
      try {
        const accessTokenResponse = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${AUTH0_DOMAIN}/api/v2/`,
          },
        });

        const tokenData = JSON.parse(atob(accessTokenResponse.split('.')[1]));
        const tokenExp = tokenData.exp * 1000;

        const currentTime = Date.now();
        const timeUntilExpiration = tokenExp - currentTime + 2000;

        console.log("Time until expiration : " + timeUntilExpiration);
        startTokenExpireTimer(() => {
          expiredAccessTokenCallback();
        }, timeUntilExpiration);

        dispatch(userSetUserToken(accessTokenResponse));
  
        const claims = await getIdTokenClaims({
          authorizationParams: {
            audience: `https://${AUTH0_DOMAIN}/api/v2/`,
          },
        });

        dispatch(userSetUserMetadata(claims));
  
        updateState('user', { isLoading: false });

        if (process.env.REACT_APP_ENV  !== 'production') {
          console.log("USER LOADED");
          console.log("METADATA LOADED");
        }

        const userIn = {
          "meta": {
            "company_id": claims[`${AUTH0_CLAIM_NAMESPACE}/company_id`],
            "role": claims[`${AUTH0_CLAIM_NAMESPACE}/user_role`]
          }
        };
        
        dispatch(userSetUserInfo(userIn));
      } catch (e) {
        if (e.error === 'consent_required') {
          try {
            await loginWithPopup({
              authorizationParams: {
                audience: `https://${AUTH0_DOMAIN}/api/v2/`,
                scope: 'openid profile email',
                prompt: 'consent',
              },
            });

            const accessTokenResponse = await getAccessTokenSilently({
              authorizationParams: {
                audience: `https://${AUTH0_DOMAIN}/api/v2/`,
              },
            });

            const tokenData = JSON.parse(atob(accessTokenResponse.split('.')[1]));
            const tokenExp = tokenData.exp * 1000;

            const currentTime = Date.now();
            const timeUntilExpiration = tokenExp - currentTime + 2000;

            console.log("Time until expiration : " + timeUntilExpiration);
            startTokenExpireTimer(() => {
              expiredAccessTokenCallback();
            }, timeUntilExpiration);

            dispatch(userSetUserToken(accessTokenResponse));

            const claims = await getIdTokenClaims({
              authorizationParams: {
                audience: `https://${AUTH0_DOMAIN}/api/v2/`,
              },
            });

            dispatch(userSetUserMetadata(claims));

            updateState('user', { isLoading: false });

            if (process.env.REACT_APP_ENV  !== 'production') {
              console.log("USER LOADED");
              console.log("METADATA LOADED");
            }

            const userIn = {
              "meta": {
                "company_id": claims[`${AUTH0_CLAIM_NAMESPACE}/company_id`],
                "role": claims[`${AUTH0_CLAIM_NAMESPACE}/user_role`]
              }
            };
            
            dispatch(userSetUserInfo(userIn));
          } catch (popupError) {
            console.error('Errore durante il login con popup:', popupError);
            updateState('user', { isLoading: true });
          }
        } else {
          console.error('Errore durante il silent auth:', e);
          updateState('user', { isLoading: true });
        }
      }
    };
  
    if (isAuthenticated && state.user.isLoading) {
      getUserData();
    }
  }, [getAccessTokenSilently, getIdTokenClaims, AUTH0_DOMAIN, ENDPOINT_BASE, isAuthenticated]);

  useEffect(() => {

    if (isAuthenticated && state.ws.isLoading) {

      const userId = userMeta?.email;

      if (process.env.REACT_APP_ENV  !== 'production') {
        console.log("LAUNCHING WB MODULE");
      }

      let ws = null;

      if (process.env.REACT_APP_ENV  === 'production') {
        ws = new WebSocketModule(
          `wss://${ENDPOINT_BASE}/websocket?userId=${userId}`,
          userId,
          onWsOnOpen,
          onWsOnMessage,
          onWsOnClose,
          onWsOnError,
          dispatch
        );
      
      } else if (process.env.REACT_APP_ENV  === 'test') {
        ws = new WebSocketModule(
          `wss://${ENDPOINT_BASE_TEST}/websocket?userId=${userId}`,
          userId,
          onWsOnOpen,
          onWsOnMessage,
          onWsOnClose,
          onWsOnError,
          dispatch
        );

        console.log(`wss://${ENDPOINT_BASE_TEST}/websocket?userId=${userId}`);
      }
      else {
        ws = new WebSocketModule(
          `ws://${ENDPOINT_BASE_TEST}/websocket?userId=${userId}`,
          userId,
          onWsOnOpen,
          onWsOnMessage,
          onWsOnClose,
          onWsOnError,
          dispatch
        );
      }

      updateState('ws', { isLoading: false });
  }

  }, [state.user.isLoading]);

  useEffect(() => {
    if( !state.device.isError && !state.gateway.isError && !state.widget.isError && !state.user.isError && !state.ws.isError && !state.history.isError ) {
      if ( !state.device.isLoading && !state.gateway.isLoading && !state.widget.isLoading && !state.user.isLoading && !state.ws.isLoading && !state.history.isLoading ) {
        if (process.env.REACT_APP_ENV  !== 'production') {
          console.log("REDIRECTING TO DASHBOARD");
        }
        customNavigate( "", '/DashboardPage/DevicePanel', null, {} );
      }
    }
    else {
      customNavigate( "",'/ErrorPage', null, {} );
    }
  }, [customNavigate, state]);

  return (
    <Grid container height="100vh" alignItems="center" justifyContent="center">
      <Grid item xs={6} container direction="row" alignItems="center" justifyContent="center">
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={250} />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h5">Loading Astreo Dashboard</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingPage;
