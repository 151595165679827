import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";

function GenUserPanel({ nBWorkspaceName, nBUser, nBUserAvatar }) {

    const [anchorEl, setAnchorEl] = useState(null);

    const { logout } = useAuth0();

    const LOGOUT_CALLBACK_URL = process.env.REACT_APP_AUTH0_LOGOUT_CALLBACK_URL || 'http://127.0.0.1:3000';

    let logoutUrl = LOGOUT_CALLBACK_URL;

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleLogoutDashboard = async () => {
        localStorage.removeItem('persist:root');
        await logout({ 
          logoutParams: { 
            returnTo: logoutUrl
          }
        })
      };

      return (
        <Box sx={{ display: 'flex', width: '100%' }}> {/* Assicurati che il Box non superi la larghezza del LeftDivider */}
                        <Button
                onClick={handleMenuOpen}
                sx={{
                    display: 'flex',
                    color: 'black',
                    textTransform: 'none',
                    textAlign: 'left',
                    width: '100%',
                    justifyContent: 'start'
                }}
            >
                <Grid container direction="row" alignItems="center" pt={3} pb={3} spacing={1} wrap="nowrap">
                    <Grid item>
                        <Avatar src={nBUserAvatar} sx={{ width: 36, height: 36 }} />
                    </Grid>
                    <Grid item sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", ml: 1 }}>
                        <Typography fontSize="0.8em" noWrap>
                            {nBWorkspaceName}
                        </Typography>
                        <Typography fontSize="0.8em" noWrap>
                            {nBUser}
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ width: "100%" }}
                
            >
                <MenuItem sx={{ width: "230px" }} disabled={true} onClick={handleMenuClose}>Settings</MenuItem>
                <MenuItem sx={{ width: "230px" }} onClick={handleLogoutDashboard}>Logout</MenuItem>
            </Menu>
        </Box>
    );
}

export default GenUserPanel;
