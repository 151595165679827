import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import DashboardWidgetMulti3Chart from '../components/DashboardPageComponents/DashboardDeviceScopeComponents/DashboardDeviceWidgets/DashboardDeviceWidgetsCharts/DashboardWidgetMulti3Chart';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCustomIntervalType, selectData, selectDataLive, selectIntervalType } from '../services/dataSlice';
import { selectWidgetsData } from '../services/widgetsSlice';
import DashboardWidgetSimpleChart from '../components/DashboardPageComponents/DashboardDeviceScopeComponents/DashboardDeviceWidgets/DashboardDeviceWidgetsCharts/DashboardWidgetSimpleChart';
import DashboardWidgetSimpleChartConsumption from '../components/DashboardPageComponents/DashboardDeviceScopeComponents/DashboardDeviceWidgets/DashboardDeviceWidgetsCharts/DashboardWidgetSimpleChartConsumption';

function ChartPage() {

    const location = useLocation();
    const obj = location.state;
  
    const sensorData = useSelector(selectData)
    const sensorDataLive = useSelector(selectDataLive)
    const widgetData = useSelector(selectWidgetsData)
    const intervalType = useSelector(selectIntervalType)
    const customIntervalType = useSelector(selectCustomIntervalType)

    const chartType = obj.chartType

    const chartGain = obj.gain

    const indexSensor = sensorData.findIndex(element => element.id === obj.deviceId)

    const indexSensorLive = sensorDataLive.findIndex(element => element.id === obj.deviceId)

    const clientIndexWidget = widgetData.findIndex(element => element.id === obj.deviceId)

    const widgetIndex = obj.widgetIndex

    return (
        <Grid container height="100vh">
            { chartType==="DashboardWidgetMulti3Chart" ?
            (
                <DashboardWidgetMulti3Chart
                    widgetIndex={widgetIndex}
                    widgetData={widgetData[clientIndexWidget].widgets[widgetIndex]}
                    deviceId={obj.deviceId}
                    data={intervalType!=='live' ? sensorData[indexSensor] : sensorDataLive[indexSensorLive]}
                    avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')}
                    liveAvgEnabled={(intervalType==='day' || intervalType==='week')}
                    lastElBlEnabled={( intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')}
                    channel0={widgetData[clientIndexWidget].widgets[widgetIndex]?.specMeta?.channel0}
                    channel1={widgetData[clientIndexWidget].widgets[widgetIndex]?.specMeta?.channel1}
                    channel2={widgetData[clientIndexWidget].widgets[widgetIndex]?.specMeta?.channel2}
                    loading={false}
                    intervalType={intervalType!=='custom' ? intervalType : customIntervalType}
                    enlargeEnable={false}
                    modifyEnable={false}
                    fullScreen={true}
                    gain={chartGain}
                />
            ) : null }
            { chartType==="DashboardWidgetSimpleChart" ? 
            (
                <DashboardWidgetSimpleChart
                    widgetIndex={widgetIndex}
                    widgetData={widgetData[clientIndexWidget].widgets[widgetIndex]}
                    deviceId={obj.deviceId}
                    data={intervalType!=='live' ? sensorData[indexSensor] : sensorDataLive[indexSensorLive]}
                    avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')}
                    liveAvgEnabled={(intervalType==='day' || intervalType==='week')} 
                    lastElBlEnabled={( intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                    channel={widgetData[clientIndexWidget].widgets[widgetIndex]?.specMeta?.channel}
                    loading={false}
                    intervalType={intervalType!=='custom' ? intervalType : customIntervalType}
                    enlargeEnable={false}
                    modifyEnable={false}
                    fullScreen={true}
                    gain={chartGain}
                />
            ) : null }
            { chartType==="DashboardWidgetSimpleChartConsumption" ? 
            (
                <DashboardWidgetSimpleChartConsumption
                    widgetIndex={widgetIndex}
                    widgetData={widgetData[clientIndexWidget].widgets[widgetIndex]}
                    deviceId={obj.deviceId}
                    data={intervalType!=='live' ? sensorData[indexSensor] : sensorDataLive[indexSensorLive]}
                    avgEnabled={(intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')}
                    liveAvgEnabled={(intervalType==='day' || intervalType==='week')} 
                    lastElBlEnabled={( intervalType!=='live' && intervalType!=='hour' && intervalType!=='custom')} 
                    channel={widgetData[clientIndexWidget].widgets[widgetIndex]?.specMeta?.channel}
                    loading={false}
                    intervalType={intervalType!=='custom' ? intervalType : customIntervalType}
                    enlargeEnable={false}
                    modifyEnable={false}
                    fullScreen={true}
                    gain={chartGain}
                />
            ) : null }
        </Grid>
    )
}

export default ChartPage;