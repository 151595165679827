import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useDispatch, useSelector } from 'react-redux';
import { dataResetData, dataResetDataHistory, dataResetDataLive, selectData, selectDataHistory, selectDataLast, selectDataLive, selectIntervalType } from '../../../services/dataSlice';
import { selectDevices } from '../../../services/devicesSlice';
import DashboardDeviceScopeDashboard from '../DashboardDeviceScopeComponents/DashboardDeviceScopeDashboard';
import DashboardDeviceScopeHistory from '../DashboardDeviceScopeComponents/DashboardDeviceScopeHistory';
import DashboardDeviceScopeConfiguration from '../DashboardDeviceScopeComponents/DashboardDeviceScopeConfiguration';
import DashboardDeviceScopeRules from '../DashboardDeviceScopeComponents/DashboardDeviceScopeRules';
import DashboardDeviceScopePermissions from '../DashboardDeviceScopeComponents/DashboardDeviceScopePermissions';
import DashboardDeviceScopeDownlink from '../DashboardDeviceScopeComponents/DashboardDaviceScopeDownlink';
import { selectWidgetsData } from '../../../services/widgetsSlice';

function TabPanel({ children, value, index }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function findIndexById(data, id) {
    return data.findIndex(element => element.id === id);
}

const DashboardDeviceScopeVisualization = ({ obj }) => {
    const [value, setValue] = useState(0);
    const sensorData = useSelector(selectData);
    const sensorDataLive = useSelector(selectDataLive);
    const sensorDataHistory = useSelector(selectDataHistory);
    const deviceData = useSelector(selectDevices);
    const widgetData = useSelector(selectWidgetsData);
    const intervalType = useSelector(selectIntervalType);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TabLabel = ({ icon, label }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
            {icon}
            <Typography variant="button" sx={{ ml: 1 }}>{label}</Typography>
        </Box>
    );

    const indexes = {
        sensor: findIndexById(sensorData, obj.deviceId),
        sensorLive: findIndexById(sensorDataLive, obj.deviceId),
        sensorHistory: findIndexById(sensorDataHistory, obj.deviceId),
        widget: findIndexById(widgetData, obj.deviceId),
        device: findIndexById(deviceData, obj.deviceId)
    };

    return (
        <Box sx={{ height: '100%' }}>
            <Tabs onChange={handleChange} value={value} aria-label="device tabs" sx={{ width: '100%' }}>
                <Tab label={<TabLabel icon={<DashboardIcon />} label="Dashboard" />} />
                <Tab label={<TabLabel icon={<TimelineIcon />} label="History" />} />
                <Tab label={<TabLabel icon={<ArrowDownwardIcon />} label="Downlinks" />} disabled />
                <Tab label={<TabLabel icon={<ConstructionIcon />} label="Configuration" />} />
                <Tab label={<TabLabel icon={<AccountTreeIcon />} label="Rules" />} disabled />
                <Tab label={<TabLabel icon={<DoNotDisturbAltIcon />} label="Permissions" />} disabled />
            </Tabs>
            <TabPanel value={value} index={0}>
                <DashboardDeviceScopeDashboard 
                    sensorData={sensorData[indexes.sensor]} 
                    sensorDataLive={sensorDataLive[indexes.sensorLive]} 
                    widgetData={widgetData[indexes.widget]} 
                    deviceData={deviceData[indexes.device]} 
                    intervalType={intervalType}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DashboardDeviceScopeHistory
                    sensorDataIndex={indexes.sensorHistory}
                    deviceData={deviceData[indexes.device]}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DashboardDeviceScopeDownlink />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DashboardDeviceScopeConfiguration 
                    deviceData={deviceData[indexes.device]}
                />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <DashboardDeviceScopeRules />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <DashboardDeviceScopePermissions />
            </TabPanel>
        </Box>
    );
};

export default DashboardDeviceScopeVisualization;
