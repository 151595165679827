import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const styles = {
    root: {
      width: '15px',
      height: '15px',
      borderRadius: '50%',
      border: '1px solid black',
      backgroundColor: 'transparent',
    },
    filledRed: {
      backgroundColor: 'red',
    },
    filledGreen: {
        backgroundColor: 'rgba(0, 255, 0, 0.7)',
    },
};

const GenOnlinePhaseCircle = ({filled}) => {
    return (
        <div
            style={filled ? { ...styles.root, ...styles.filledGreen } : { ...styles.root, ...styles.filledRed }}
        />
    );
}

const DashboardGenOnlinePhaseActive = ({sensorData, renderSize, timestamp, text}) => {
    
    const [size,setSize] = useState(renderSize)

    useEffect(() => {
        setSize(renderSize)
    }, [renderSize])

    return(
        <Box width="100%">
            {size==="small" && (
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    alignContent="center"
                    direction="row"
                    spacing={3}
                    width="100%"
                >
                    <Box sx={{ pt: 1, pl: 2 }} width="5%" justifyContent="flex-start">
                        <PowerSettingsNewIcon fontSize="large"/>
                    </Box>
                    <Box justifyContent="center">
                        <Stack direction="column">
                            <Box>
                                <Typography sx={{ fontSize: 18, fontWeight: 'bold'}} color="text.primary" >
                                    {text}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: 12 }} color="text.primary" >
                                    {timestamp}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box
                        justifyContent="flex-end"
                        sx={{ pr: 2 }}
                    >
                        <Stack alignItems="center" direction="row" spacing={1}>
                            <Typography>Attiva</Typography>
                            <GenOnlinePhaseCircle filled={false}/>
                        </Stack>
                    </Box>
                </Stack>
            )}
            {size==="medium" && (
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography sx={{ fontSize: 25 }} variant="body2">
                        Active Phases
                    </Typography>
                    <Stack
                        direction="column"
                        display="flex"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <GenOnlinePhaseCircle filled={false}/>
                                <Typography>{text}</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            )}
            {size==="large" && (
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography sx={{ fontSize: 25 }} variant="body2">
                        Active Phases
                    </Typography>
                    <Stack
                        direction="column"
                        display="flex"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <GenOnlinePhaseCircle filled={false}/>
                                <Typography>{text}</Typography>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            )}
        </Box>
    )
};

const DashboardDeviceWidgetPhaseActive = ({sensorData, renderSize, timestamp, text}) => {

    return (
        <Box
            style={{
                display:"flex",
                height:"100%",
                width:"100%",
                justifyContent:"center",
                alignItems:"center"
            }}        
        >
                <DashboardGenOnlinePhaseActive sensorData={sensorData} renderSize={renderSize} timestamp={timestamp} text={text}/>
        </Box>
    );
}

export default DashboardDeviceWidgetPhaseActive;