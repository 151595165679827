import { DateTime } from "luxon";

function isParsableDate(input) {

    const c_input = new Date(input);

    if(isNaN(c_input) || c_input.getTime() === 0)
    {
        return false;
    } else {
        return true;
    }
}

function dateFormat(dataIn) {

    if(!(dataIn instanceof Date)){dataIn=new Date(dataIn)}

    const formattedDate = DateTime.fromJSDate(dataIn);

    return formattedDate;
}

function toUTCDateTime(luxonDateTime) {
    return luxonDateTime.toUTC();
  };

function dateFormatToString(dataIn) {

    const formattedDate = dataIn.toFormat('ccc, d MMM yyyy, HH:mm:ss')

    return formattedDate;
}

function subtractOneDay(dataIn) {

    const newDate = dataIn.minus({ days: 1 });
    return newDate;
}

export  { dateFormat, toUTCDateTime, dateFormatToString, isParsableDate, subtractOneDay };