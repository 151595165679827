import { createSlice } from '@reduxjs/toolkit';

// reducer.js
const initialState = {
    userInfo: {},
    userMetadata: {},
    userToken: ""
    // Altri campi dello stato
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        userSetUserInfo : (state, action) => {
            state.userInfo = action.payload;
        },
        userSetUserMetadata : (state, action) => {
            state.userMetadata = action.payload;
        },
        userSetUserToken : (state, action) => {
            state.userToken = action.payload;
        }
    }
});
 
export const {
    userSetUserInfo,
    userSetUserMetadata,
    userSetUserToken
} = userSlice.actions;

export const selectUserInfo = (state) => state.user.userInfo
export const selectUserMeta = (state) => state.user.userMetadata
export const selectUserToken = (state) => state.user.userToken

export default userSlice.reducer;
