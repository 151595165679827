import { createSlice } from '@reduxjs/toolkit';
import { devicesAddDevice, devicesDeleteDevice, devicesModifyDevice } from './devicesSlice';

const initialState = {
    widgets:[],
    count:0
};

export const widgetsSlice = createSlice({
    name: 'widgets',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        widgetsUpdateWidget: {
            reducer: (state, action) => {

                const oldData = state.widgets

                const index = oldData.findIndex(element => element.id === action.payload.id)
                
                let newArray = [...state.widgets];
                newArray[index] = action.payload;
                state.widgets = newArray
            },
            prepare: (json) => {

                //See if some parsing makes sense

                return { payload: json.widgetArray }
            }
        },
        widgetsAddWidget: {
            reducer: (state, action) => {

                const oldData = state.widgets

                const index = oldData.findIndex(element => element.id === action.payload.id)

                let newArray = [...state.widgets];
                newArray[index].push(action.payload)
                state.widgets = newArray
            },
            prepare: (json) => {

                //See if some parsing makes sense

                return { payload: json.widgetArray }
            },
        },
        widgetsAddAllWidgets: {
            reducer: (state, action) => {
    
                let newArray = [...action.payload];
                state.widgets = newArray
                state.count = action.payload.length
            }
        },
        widgetsRemoveWidget : (state) => {
        },
    },
    extraReducers: (builder) => { 
        builder
            .addCase(devicesAddDevice, (state, action) => {
                // action is inferred correctly here if using TS
                const oldData = state.widgets

                const newDevice = 
                {
                    id: action.payload.id,
                    widgets: 
                    [
                        {
                            type: "DashboardDeviceWidgetCard",
                            genMeta: {
                                name: "DashboardDeviceWidgetCard",
                                grid: { i: "0", x: 4, y: 3, w: 6, h: 8, renderSize: "large" }
                            },
                            specMeta: {
                            }
                        },
                        {
                            type: "DashboardDeviceWidgetRadio",
                            genMeta: {
                                name: "DashboardDeviceWidgetRadio",
                                grid: { i: "1", x: 4, y: 3, w: 6, h: 8, renderSize: "small" }
                            },
                            specMeta: {
                            }
                        },
                        {
                            type: "DashboardDeviceWidgetTimestamp",
                            genMeta: {
                                name: "DashboardDeviceWidgetTimestamp",
                                grid: { i: "2", x: 4, y: 3, w: 6, h: 8, renderSize: "small" }
                            },
                            specMeta: {
                            }
                        },
                    ]
                }

                let newArray = [...oldData];
                newArray.push(newDevice)
                state.widgets = newArray
            })
            // You can chain calls, or have separate `builder.addCase()` lines each time
            .addCase(devicesDeleteDevice, (state, action) => {

                const oldData = state.widgets

                const index = oldData.findIndex(element => element.id === action.payload)
                
                let newArray = [...oldData];
                newArray.splice(index, 1)
                state.widgets = newArray
            })
            .addCase(devicesModifyDevice, (state, action) => {

                /*const oldData = state.widgets

                //const index = oldData.findIndex(element => element.id === action.payload.id)
                
                let newArray = [...oldData];
                newArray[index] = action.payload;
                state.widgets = newArray*/
            })
            // and provide a default case if no other handlers matched
            .addDefaultCase((state, action) => {
                
            })
        },
    }
);
 
export const {
                widgetsUpdateWidget,
                widgetsAddAllWidgets } = widgetsSlice.actions;

export const selectWidgetsData = (state) => state.widgets.widgets;
export const selectWidgetsCount = (state) => state.widgets.count;

export default widgetsSlice.reducer;
