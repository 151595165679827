import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import { useState } from "react";

const DashboardPageDeviceAddDeviceForm = ({dialogTitle, dialogContextText,buttonLabel0,buttonLabel1,open,handleButton0,handleClose, outDeviceJson}) => {

    const initJsonOut = {
        "id": "",
        "name": "",
        "dev_eui": ""
    }

    const [jsonOut, setJsonOut] = useState(initJsonOut)

    const handleIdChange = (event) => {
        // TODO : Check not supported chars
        setJsonOut(
            {
                id: event.target.value,
                name: event.target.value,
                dev_eui: jsonOut.dev_eui
            }
        )
    };

    const handleDevEuiChange = (event) => {
        // TODO : Here must be only numbers (8)
        setJsonOut(
            {
                id: jsonOut.id,
                name: jsonOut.name,
                dev_eui: event.target.value
            }
        )
    };

    const _handleButton0 = () => {
        handleButton0(jsonOut)
    }

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContextText}
                </DialogContentText>
            </DialogContent>
            <Typography
                variant="h6" 
                    sx={{ align:'justify' }}
                >
                    <TextField
                        label="Device Name"
                        onChange={event => handleIdChange(event)}
                        fullWidth
                    />
            </Typography>
            <Typography
                variant="h6" 
                    sx={{ align:'justify' }}
                >
                    <TextField
                        label="Device EUI"
                        onChange={event => handleDevEuiChange(event)}
                        fullWidth
                    />
            </Typography>
            <DialogActions>
                <Button onClick={_handleButton0}>{buttonLabel0}</Button>
                <Button onClick={handleClose} autoFocus>{buttonLabel1}</Button>
            </DialogActions>
            </Dialog>
        </Box>
    );
  }

export default DashboardPageDeviceAddDeviceForm;