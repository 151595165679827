import React from 'react';
import { Box, Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import DashboardPageLeftDivider from '../components/DashboardPageComponents/DashboardPageLeftDivider';
import astreoLogoWebP from '../assets/astreoR-BLACK_logo_sottotitolo_tramonto_positivo.webp';
import astreoLogoPNG from '../assets/astreoR-BLACK_logo_sottotitolo_tramonto_positivo.png'
import { useSelector } from 'react-redux';
import { selectUserMeta } from '../services/userSlice';
import UiWsOnCloseAlert from '../uiComponents/uiWsOnCloseAlert';

function DashboardPage() {
  const userInfo = useSelector(selectUserMeta);

  return (
    <Grid container height="100vh" direction="row" wrap="nowrap">
      <Grid item xs={2} bgcolor='#E5E7EB'>
        <DashboardPageLeftDivider userInfo={userInfo} />
      </Grid>
      <Grid item xs={10}>
        <Grid container height="100%" direction="column" wrap="nowrap">
          <Grid item display="flex" justifyContent="flex-end" pr={4} xs={1}>
            <Box pt={3} pr={1} component="picture" sx={{ width: "15%" }}>
              <source srcSet={astreoLogoWebP} type="image/webp" />
              <source srcSet={astreoLogoPNG} type="image/png" />
              <img src={astreoLogoPNG} alt="Astreo Logo" loading="lazy" style={{ width: '100%' }} />
            </Box>
          </Grid>
          <Grid item xs={11} sx={{ overflow: "auto" }}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <UiWsOnCloseAlert userInfo={userInfo}/>
      </Grid>
    </Grid>
  );
}

export default DashboardPage;
