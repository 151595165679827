import React from 'react';
import { Grid, Icon, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectGateways } from '../../../services/gatewaysSlice';
import DashboardGatewayVisualization from '../DashboardPanelComponents/DashboardGatewayVisualization';
import RouterIcon from '@mui/icons-material/Router';

function DashboardPageConcentratorPanel() {

    const gateways = useSelector(selectGateways)

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
        >
            <Grid 
                item
                xs={1}
                display="flex"
                alignItems="center"
            >
                <Grid
                    container
                    direction="row"
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Typography
                            fontSize={20}
                            pl={5}
                            pt={4}
                            sx={{ fontWeight: 'bold' }}
                        >
                            <Icon>
                                <RouterIcon />
                            </Icon>
                            <span style={{ marginLeft: 8 }} />
                                CONCENTRATORS
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid  // Visualization Panel
                item
                xs={11}
            >
                <DashboardGatewayVisualization gateways={gateways}/>
            </Grid>
        </Grid>  
    )
}

export default DashboardPageConcentratorPanel;