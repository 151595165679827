import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Box, Stack } from '@mui/system';
import GenOnlineCircle from '../../GeneralComponents/GenOnlineCircle';
import { useNavigate } from 'react-router-dom';
import GenButtonList from '../../GeneralComponents/GenButtonList';
import { useCustomNavigate } from '../../../navigation/customNavigate';

function DashboardDeviceGrid({jsonData, devices}) {

    const customNavigate = useCustomNavigate()
    const [expMenuAnchorEl, setExpMenuAnchorEl] = useState(null);

//    const handleOnExpMenuClick = (event) => {
//        setExpMenuAnchorEl(event.currentTarget)
//    };

    function handleExpMenuAnchorMenuClose(out) {
        setExpMenuAnchorEl(out);
    };

    function _onDeviceButtonClick(id) {

        const obj = {
            "deviceId": id,
            "intervalType": "live"
        }

        customNavigate( ``, `/DashboardPage/DashboardDeviceScope/`, obj, {} );
    };

    return (
        <Box>
            <Grid
                container
                direction="row"
                dispaly="flex"
                width="100%"
                height="100%"
                spacing="25px"
            >
                { devices.length!==0 &&
                    devices.map(item => (
                    <Grid 
                        item
                        key={item.id}
                        xs={4}
                    >
                        <Box
                            sx={{
                                border:'1px solid black',
                            }}
                        >
                            <Stack
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                spacing="5px"
                            >
                                <Box
                                    width="100%"
                                    height="100%"
                                    pl={2}
                                >
                                    <GenOnlineCircle filled={true}/>
                                </Box>
                                <Box>
                                    <Button
                                        sx={{
                                            color:"primary.main",
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                                color: "red"
                                            }
                                        }}
                                        onClick={ () => _onDeviceButtonClick(item.id)}
                                    >
                                            <Stack>
                                                <Typography>
                                                    {item.name}
                                                </Typography>
                                                <Typography>
                                                    {item.dev_eui}
                                                </Typography>
                                            </Stack>
                                    </Button>
                                </Box>
                                <Box
                                    alignContent="center"
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    {/*<IconButton onClick={() => {alert("")}} aria-label="modify" size="medium">
                                        <AllOutIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton onClick={handleOnExpMenuClick} aria-label="delete" size="medium">
                                        <MoreVertIcon fontSize="inherit" />
                                    </IconButton>*/}
                                    <GenButtonList 
                                        anchorElement={expMenuAnchorEl}
                                        onBClose={handleExpMenuAnchorMenuClose}
                                        id={item.id}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    ))}
            </Grid>
        </Box>
    )
}
  
export default DashboardDeviceGrid;