import React from 'react';
import { Icon, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import packageJson from '../../../package.json';
import { useNavigate } from 'react-router-dom';
import GenUserPanel from '../GeneralComponents/GenUserPanel';

import PeopleIcon from '@mui/icons-material/People';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import RouterIcon from '@mui/icons-material/Router';
import { useCustomNavigate } from '../../navigation/customNavigate';

const buttonList = [
  {
    text:"Devices",
    icon:<SettingsInputAntennaIcon />,
    link: "/DashboardPage/DevicePanel"
  },
  {
    text:"Concentrators",
    icon:<RouterIcon />,
    link: "/DashboardPage/ConcentratorPanel"
  },
  {
    text:"Reports",
    icon:<TextSnippetIcon />,
    link: "/DashboardPage/Reports"
  },
  {
    text:"Members",
    icon:<PeopleIcon />,
    link: "/DashboardPage/Members"
  },
  {
    text:"Rules",
    icon:<AccountTreeIcon />,
    link: "/DashboardPage/Rules"
  },
  {
    text:"Workspace",
    icon:<WorkspacesIcon />,
    link: "/DashboardPage/Workspace"
  },
  {
    text:"Integrations",
    icon:<IntegrationInstructionsIcon />,
    link: "/DashboardPage/Integrations"
  }
]

const isButtonDisabled = (text) => ["Reports", "Rules", "Workspace", "Integrations", "Members"].includes(text);

const DashboardPageLeftDivider = ({ userInfo }) => {
  const customNavigate = useCustomNavigate();
  const appVersion = packageJson.version;

  return (
    <Stack sx={{ width: '100%' }}>
      <GenUserPanel nBWorkspaceName="Workspace" nBUser={userInfo?.email} nBUserAvatar={userInfo?.picture} />
      <List sx={{ width: '100%' }}>
        {buttonList.map((button, index) => (
          <ListItem key={button.text} disablePadding>
            <ListItemButton
              onClick={() => customNavigate( "", button.link, {} )}
              disabled={isButtonDisabled(button.text)}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                  transform: 'scale(0.95)',
                  borderRadius: 3,
                },
                width: '100%', // Assicurati che il bottone occupi l'intera larghezza
              }}
            >
              <Icon>{button.icon}</Icon>
              <Typography sx={{ ml: 1, fontSize: 15 }}>
                {button.text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Typography
        fontSize={12}
        sx={{ position: 'absolute', bottom: 0, left: 0 }}
      >
        V. {appVersion} {process.env.REACT_APP_ENV === 'test' ? 'BETA TEST' : 'BETA'}
      </Typography>
    </Stack>
  );
}

export default DashboardPageLeftDivider;
