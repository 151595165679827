import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import AlertDialog from '../../../GeneralComponents/GenDialog';
import { devicesModifyDevice } from '../../../../services/devicesSlice';

const DashboardLoRaWANConfiguration = ({deviceData}) => {

    const dispatch = useDispatch()

    const [updatedData, setUpdatedData] = useState(deviceData);
    const [onDialogOpen, setOnDialogOpen] = useState([false]);
    const [isModified, setIsModified] = useState(false);

    const handleChangeModifyEnable = (event) => {
        
        if(isModified)
        {
            setOnDialogOpen([true])
        }
    };

    const handleDialogClose = (event) => {
        setOnDialogOpen([false])
    };

    const handleSaveButton = () => {

        handleSave()
        handleDialogClose()
        setIsModified(false)
    }

    const handleSave = () => {
        dispatch(devicesModifyDevice(updatedData))
    };

    const handleDevEuiChange = (event) => {

        setUpdatedData({
            id: updatedData?.id,
            name: updatedData?.name,
            dev_eui: event.target.value,
            description: updatedData?.description,
            onlineTimeout: updatedData?.onlineTimeout,
            status: updatedData?.status,
            tags: updatedData?.tags,
            flags: updatedData?.flags,
            location: {
                latitude: updatedData?.location?.latitude,
                longitude: updatedData?.location?.longitude,
                altitude: updatedData?.location?.altitude
            },   
        })

        setIsModified(true)
    };

    return(
        <Box>
            <Stack
                display="flex"
                spacing = {3}
            >
                <Box>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Device EUI
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                            align:'justify'
                        }}
                    >
                        <TextField
                            defaultValue={deviceData?.dev_eui}
                            inputProps={{ maxLength: 50 }}
                            sx={{
                                width: "30%"
                            }}
                            onChange = {handleDevEuiChange}
                            disabled={true}
                        />
                    </Typography>
                </Box>
                {/*<Box
                    display='flex'
                    justifyContent='flex-end'
                >
                    <Button 
                        variant={ isModified ? 'contained' : 'outlined' }
                        onClick={handleChangeModifyEnable}
                    >
                        SAVE
                    </Button>
                    <AlertDialog
                        dialogTitle={"Do you want to save changes?"} 
                        dialogContextText={""}
                        buttonLabel0={"Save"}
                        buttonLabel1={"Cancel"}
                        open={onDialogOpen[0]} 
                        handleClose={handleDialogClose}
                        handleButton0={handleSaveButton}
                    />
                    </Box>*/}
            </Stack>
        </Box>
    );
}

export default DashboardLoRaWANConfiguration;