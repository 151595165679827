const postToDatabase = async (url, dataToWrite, accToken) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accToken}`,
        },
        body: JSON.stringify(dataToWrite),
      });
  
      // Gestisci la risposta del server se necessario
      return response;
    } catch (error) {
      console.error('Errore durante l\'operazione di scrittura:', error);
    }
};

const putToDatabase = async (url, dataToWrite, accToken) => {
  try {
    console.log(url)
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accToken}`,
      },
      body: JSON.stringify(dataToWrite),
    });

    // Gestisci la risposta del server se necessario
    return response;
  } catch (error) {
    console.error('Errore durante l\'operazione di scrittura:', error);
  }
};

const readFromDatabase = async (url, accToken) => {

    try {
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${accToken}`,
            },
        });
  
        // Gestisci la risposta del server se necessario
        return response;

    } catch (error) {
        console.error('Errore durante l\'operazione di scrittura:', error);
    }
};

export { postToDatabase, putToDatabase, readFromDatabase };