import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideUiWsOnCloseAlert, selectUiWsOnCloseAlert, selectWsReopenIsLoading, setWsReopenIsLoading } from '../services/uiSlice';
import { selectWebSocketInstance } from '../services/wsSlice';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Modal, CircularProgress } from '@mui/material';
import { WebSocketModule, getWsStatus } from '../ws/webSocket';
import { onWsOnClose, onWsOnError, onWsOnMessage, onWsOnOpen } from '../ws/wsAppCallbacks';

const ENDPOINT_BASE = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';
const ENDPOINT_BASE_TEST = process.env.REACT_APP_BACKEND_URL || 'localhost:3003';

const UiWsOnCloseAlert = ({userInfo}) => {
  const dispatch = useDispatch();
  const ws = useSelector(selectWebSocketInstance)
  const uiWsOnCloseAlert = useSelector(selectUiWsOnCloseAlert);
  const loading = useSelector(selectWsReopenIsLoading);

  const handleClose = () => {
    dispatch(hideUiWsOnCloseAlert());
  };

  const handleConfirm = async () => {
    const wsState = getWsStatus(ws);

    dispatch(setWsReopenIsLoading(true));

    if(wsState==="CLOSED")
    {
        if (process.env.REACT_APP_ENV  === 'production') {
            new WebSocketModule(
            `wss://${ENDPOINT_BASE}/websocket?userId=${userInfo?.email}`,
              userInfo?.email,
              onWsOnOpen,
              onWsOnMessage,
              onWsOnClose,
              onWsOnError,
              dispatch
            );
        
        } else if(process.env.REACT_APP_ENV  === 'test') {
            new WebSocketModule(
              `wss://${ENDPOINT_BASE_TEST}/websocket?userId=${userInfo?.email}`,
              userInfo?.email,
              onWsOnOpen,
              onWsOnMessage,
              onWsOnClose,
              onWsOnError,
              dispatch
            );
        }
        else {
            new WebSocketModule(
              `ws://${ENDPOINT_BASE_TEST}/websocket?userId=${userInfo?.email}`,
              userInfo?.email,
              onWsOnOpen,
              onWsOnMessage,
              onWsOnClose,
              onWsOnError,
              dispatch
            );
        }
    }
  };

  return (
    <>
      <Dialog
        open={uiWsOnCloseAlert && !loading}
        onClose={handleClose}
        aria-labelledby="custom-alert-dialog-title"
        aria-describedby="custom-alert-dialog-description"
        PaperProps={{
          style: {
            position: 'absolute',
            top: '10%',
            minWidth: '500px',
            maxWidth: '80%',
            borderRadius: '3'
          },
        }}
      >
        <DialogContent>
          <DialogContentText id="custom-alert-dialog-description">
            Connection timed out, Reconnect
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}> {/* Centering the Confirm button */}
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={loading}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(3px)', // Apply a blur effect to the backdrop
        }}
      >
        <CircularProgress />
      </Modal>
    </>
  );
};

export default UiWsOnCloseAlert;
