class WebSocketModule {
  constructor(url, userId, onOpen, onMessage, onClose, onError, dispatch) {
    this.ws = new WebSocket(url);
    this.userId = userId;
    this.connectionUuid = null;
    this.onMessageHandler = null;
    this.onErrorHandler = null;

    this.ws.onopen = async () => { onOpen(dispatch); }
    this.ws.onmessage = async (event) => {
      if (this.onMessageHandler) {
        this.onMessageHandler(JSON.parse(event.data)); 
      }
      onMessage(dispatch, event.data, this); 
    }
    this.ws.onclose = async () => { onClose(dispatch); }
    this.ws.onerror = async (error) => { onError(dispatch, error); }
  }

  getConnectionState() {
    switch (this.ws.readyState) {
      case WebSocket.CONNECTING:
        return 'CONNECTING';
      case WebSocket.OPEN:
        return 'OPEN';
      case WebSocket.CLOSING:
        return 'CLOSING';
      case WebSocket.CLOSED:
        return 'CLOSED';
      default:
        return 'UNKNOWN';
    }
  }

  setConnectionUuid(connectonId) {
    this.connectionUuid = connectonId;
  };

  open() {
    this.ws.onopen();
  }

  send(message) {
    this.ws.send(message);
  }

  close() {
    this.ws.close();
  }

  addMessageHandler(handler) {
    this.onMessageHandler = handler;
  }

  removeMessageHandler() {
    this.onMessageHandler = null;
  }

  addErrorHandler(handler) {
    this.onErrorHandler = handler;
  }

  removeErrorHandler() {
    this.onErrorHandler = null;
  }
}

const sendWsMessage = async (ws, message) => {
  try {
      ws.send(message)
  } catch (error) {
      console.error(`Error :`, error);
  }
};

const setWsConnectionUuid = (ws, connectonId) => {
  ws.setConnectionUuid(connectonId); 
}

const getWsStatus = (ws) => {
  return ws.getConnectionState(); 
}

const sendWsMessageWait = (ws, message) => {

  const uniqueIdentifier = generateUniqueIdentifier();

  const messageWithId = {
    "connectionUuid": ws.connectionUuid,
    "message": message,
    "uniqueIdentifier": uniqueIdentifier,
  };

  return new Promise((resolve, reject) => {
    ws.send(JSON.stringify(messageWithId));

    ws.addMessageHandler((event) => {
      const uniqueIdentifierRec = event.message.uniqueIdentifier
      if(uniqueIdentifierRec===uniqueIdentifier)
      {
        resolve(event.data);
        ws.removeMessageHandler();
      }
    });

    ws.addErrorHandler((error) => {
      console.error("Errore durante l'invio del messaggio WebSocket:", error);
      reject(error);
      ws.removeErrorHandler();
    });
  });
};

function generateUniqueIdentifier() {
  return Math.random().toString(36).substring(7);
}

export { 
  WebSocketModule,
  setWsConnectionUuid,
  sendWsMessageWait,
  sendWsMessage,
  getWsStatus };
