import { Box } from '@mui/material';
import React from 'react';

const styles = {
    root: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: 'transparent',
    },
    filledRed: {
      backgroundColor: 'red',
    },
    filledGreen: {
        backgroundColor: '#48BB78',
    },
};

const GenOnlineCircle = ({filled}) => {
    return (
        <Box
            style={filled ? { ...styles.root, ...styles.filledGreen } : { ...styles.root, ...styles.filledRed }}
        />
    );
}

export default GenOnlineCircle;